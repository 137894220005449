import React from 'react';

import { Modal, ModalContainer, Cancel, Message, ButtonsContainer } from '../../styles/modal';

type onCloseButtonFunction = (close: boolean) => void;

export interface IProps {
    onCloseButton: onCloseButtonFunction;
    bodyText: React.ReactNode;
}

export const InformationModal: React.FunctionComponent<IProps> = ({ onCloseButton, bodyText }) => {
    return (
        <ModalContainer>
            <Modal>
                <Message>{bodyText}</Message>
                <ButtonsContainer>
                    <Cancel onClick={() => onCloseButton(false)}>OK</Cancel>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
