import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import EncoreLogoFR from '../../assets/icons/encore_logo_min_fr.svg';
import EncoreLogoEN from '../../assets/icons/encore_logo_min_en.svg';
import UserIcon from '../../assets/icons/user_icon.svg';
import DownArrow from '../../assets/icons/dropdown_arrow.svg';

import {
    HeaderContainer,
    Arrow,
    Icon,
    UserContainer,
    UserName,
    AdminMenu,
    HeaderLogo,
} from './styles';

import { ROLES } from '../../slices/userSlice';
import { logoutUser } from '../../slices/authSlice';
import LANGUAGES from '../../constants/languages';

export interface IProps {
    userName?: string;
    role?: string | null;
    profileModal?: (open: boolean) => void;
    passwordModal?: (open: boolean) => void;
    createUserModal?: (open: boolean) => void;
}

export const DashboardHeader: React.FunctionComponent<IProps> = ({
    userName,
    role,
    profileModal,
    passwordModal,
    createUserModal,
}) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language
    const history = useHistory();
    const dispatch = useDispatch();

    // Changes logo according to language
    const encoreLogo = language === LANGUAGES.EN ? EncoreLogoEN : EncoreLogoFR;

    const openProfile = () => {
        if (profileModal) {
            profileModal(true);
            setShowMenu(false);
        }
    };

    const openPassword = () => {
        if (passwordModal) {
            passwordModal(true);
            setShowMenu(false);
        }
    };

    const openCreateUser = () => {
        if (createUserModal) {
            createUserModal(true);
            setShowMenu(false);
        }
    };

    return (
        <>
            <HeaderContainer>
                <HeaderLogo
                    onClick={() => {
                        history.push('/');
                    }}
                    src={encoreLogo}
                    alt={t('homepage.logoAlt')}
                />
                <UserContainer onClick={() => setShowMenu(!showMenu)}>
                    <UserName>{t(`admin.greeting`, { userName })}</UserName>
                    <Icon src={UserIcon} alt={t('admin.userIcon')} />
                    <Arrow src={DownArrow} alt={t('admin.userIcon')} />
                </UserContainer>
            </HeaderContainer>
            {showMenu && (
                <AdminMenu>
                    <div aria-hidden="true" onClick={openProfile}>
                        {t('admin.yourProfile')}
                    </div>
                    <div aria-hidden="true" onClick={openPassword}>
                        {t('admin.changePassword')}
                    </div>
                    {role === ROLES.ADMIN && (
                        <div aria-hidden="true" onClick={openCreateUser}>
                            {t('admin.createUser')}
                        </div>
                    )}
                    <div
                        aria-hidden="true"
                        onClick={() => {
                            dispatch(logoutUser());
                        }}
                    >
                        {t('admin.logout')}
                    </div>
                </AdminMenu>
            )}
        </>
    );
};
