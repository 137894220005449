/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import { DropdownContainer, DropdownOuterContainer, Select } from './styles';

export interface IProps {
    question: number;
    text: string;
    options: QuestionOptionType[];
    value?: number;
}

export const DropdownQuestion: React.FunctionComponent<IProps> = ({ question, options, value }) => {
    const [answer, setAnswer] = useState<string>();
    const dispatch = useDispatch();

    useEffect(() => {
        const selectedOption = options.filter((option) => option.selected.length > 0);
        if (selectedOption.length > 0) {
            setAnswer(selectedOption[0].text);
        }
    }, [options]);

    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = event.target.options.selectedIndex;
        const dropdownOptions = options;

        setAnswer(dropdownOptions[selectedIndex - 1].text);

        dispatch(
            saveObject({
                question_id: question,
                answer_offered_id: dropdownOptions[selectedIndex - 1].id,
            }),
        );
    };

    return (
        <DropdownOuterContainer>
            <DropdownContainer>
                <Select value={answer} onChange={handleChange}>
                    <option key={''} value={''} />
                    {options.map((option) => {
                        return (
                            <option key={option.id} value={option.text}>
                                {t(`survey.${option.text}`)}
                            </option>
                        );
                    })}
                </Select>
            </DropdownContainer>
        </DropdownOuterContainer>
    );
};
