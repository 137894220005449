/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import * as jose from 'jose';

import { RootState } from '../store';
import { IUser } from './userSlice';

import { BACKEND_ROUTES } from '../constants/routes';
import { HTTPPostRequest } from '../utils/request';

import { ServerError } from '../types/error';

export interface ILoginResponseSuccess {
    data: {
        user: IUser;
        token: string | null;
    };
}

export interface IAuthState {
    user: IUser | null;
    password: string | null;
    errors: ServerError;
    loading: boolean;
}

export interface ILoginResponseSuccess {
    data: {
        user: IUser;
        token: string | null;
    };
}

export const initialState: IAuthState = {
    user: null,
    password: null,
    errors: null,
    loading: false,
};

export enum PasswordStatus {
    ok = 'OK',
}

export const resetOldToken = () => {
    const oldToken = localStorage.getItem('token');
    if (!oldToken) {
        return;
    }
    const decodedJwt = jose.decodeJwt(oldToken);
    // if before 20th Nov 2022 : reset the token
    if (decodedJwt?.iat && decodedJwt.iat < 1668997718) {
        localStorage.removeItem('token');
    }
};

export const loginUser = createAsyncThunk(
    'auth/login',
    async (
        params: {
            email: string;
            password: string;
        },
        { dispatch },
    ) => {
        try {
            const response = await axios.post(BACKEND_ROUTES.LOGIN, {
                email: params.email,
                password: params.password,
            });
            const apiToken: string = response.data.data.token ? response.data.data.token : '';
            localStorage.setItem('token', apiToken);
            dispatch(setUserAction(response.data));
        } catch (error) {
            const errorText = error.response.data as string;
            dispatch(setErrorsAction(errorText));
        }
    },
);

export const whoAmI = createAsyncThunk('auth/whoami', async (_, { dispatch }) => {
    try {
        const response = await axios.get(BACKEND_ROUTES.WHOAMI, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        const user = {
            data: {
                token: localStorage.getItem('token'),
                user: response.data.data,
            },
        };
        dispatch(setUserAction(user));
    } catch (error) {
        localStorage.removeItem('token');
        dispatch(setUserAction(null));
        const errorText = error.response.data as string;
        dispatch(setErrorsAction(errorText));
    }
});

// When user logs out, clear api token from local storage and set store to initial state
export const logoutUser = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
    try {
        await HTTPPostRequest(BACKEND_ROUTES.LOGOUT, {});
    } catch (error) {
        const errorText = error.response.data as string;
        dispatch(setErrorsAction(errorText));
    } finally {
        localStorage.removeItem('token');
        dispatch(setUserAction(null));
    }
});

export const sendCreatePasswordEmail = createAsyncThunk(
    'auth/sendCreatePasswordEmail',
    async (
        params: {
            email: string;
        },
        { dispatch, rejectWithValue },
    ) => {
        try {
            localStorage.removeItem('token');
            const { status } = await axios.post<{ data: any }>(BACKEND_ROUTES.SEND_WELCOME_EMAIL, {
                email: params.email,
            });

            if (status === 204) {
                dispatch(setSendCreatePasswordEmailSuccess(PasswordStatus.ok));
            }
        } catch (e) {
            dispatch(setSendCreatePasswordEmailSuccessErrorAction(PasswordStatus.ok));
            return rejectWithValue({ message: PasswordStatus.ok });
        }
    },
);

export const sendResetPasswordEmail = createAsyncThunk(
    'auth/sendResetPasswordEmail',
    async (
        params: {
            email: string;
        },
        { dispatch, rejectWithValue },
    ) => {
        try {
            const { status } = await axios.post<{ data: any }>(BACKEND_ROUTES.SEND_RESET_PASSWORD, {
                email: params.email,
            });

            if (status === 204) {
                dispatch(setSendCreatePasswordEmailSuccess(PasswordStatus.ok));
            }
        } catch (e) {
            dispatch(setSendCreatePasswordEmailSuccessErrorAction(PasswordStatus.ok));
            return rejectWithValue({ message: PasswordStatus.ok });
        }
    },
);

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserAction(state, action: PayloadAction<ILoginResponseSuccess | null>) {
            state.user = action.payload?.data?.user ? action.payload.data.user : null;
            state.password = null;
            state.loading = false;
            state.errors = null;
        },
        setAuthenticatedUser(state, action: PayloadAction<IUser | null>) {
            state.user = action.payload;
            state.password = null;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action) {
            state.user = null;
            state.password = null;
            state.errors = action.payload;
            state.loading = false;
        },
        setSendCreatePasswordEmailSuccess(state, action) {
            state.user = null;
            state.password = action.payload;
            state.loading = false;
            state.errors = null;
        },
        setSendCreatePasswordEmailSuccessErrorAction(state, action) {
            state.user = null;
            state.password = action.payload;
            state.loading = false;
            state.errors = null;
        },
    },
});

export const selectAuthenticatedUser = (state: RootState) => state.auth.user;
export const selectPasswordEmailStatus = (state: RootState) => state.auth.password;

export const {
    setUserAction,
    setAuthenticatedUser,
    setErrorsAction,
    setSendCreatePasswordEmailSuccess,
    setSendCreatePasswordEmailSuccessErrorAction,
} = slice.actions;

export default slice.reducer;
