import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const SelectboxMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
`;

export const SelectboxContainer = styled.div`
    display: grid;

    @media ${sizes.XP} {
        grid-gap: 38px;
    }

    @media ${sizes.G} {
        grid-template-columns: auto auto;
        grid-gap: 64px;
    }
`;

export const SelectboxButton = styled.button<{ selected: boolean; stringLength: number }>`
    font-family: PoppinsRegular;
    border: 1px solid ${(props) => (props.selected ? colours.pink : colours.mediumGrey)};
    color: ${(props) => (props.selected ? colours.white : colours.black)};
    background-color: ${(props) => (props.selected ? colours.pink : colours.white)};
    border-radius: 6px;
    transition: all 0.5s ease;

    :hover {
        border: 1px solid ${colours.pink};
    }

    @media ${sizes.XP} {
        font-size: 18px;
        width: 340px;
        height: 64px;
    }

    @media ${sizes.G} {
        font-size: 15px;
        width: 237px;
        height: 64px;
    }
`;

export const InputContainer = styled.div`
    margin-top: 50px;
    text-align: left;

    @media ${sizes.XP} {
        font-size: 18px;
    }

    @media ${sizes.G} {
        font-size: 16px;
    }
`;

export const InputTitle = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
`;

export const Input = styled.input`
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;

    @media ${sizes.XP} {
        margin-bottom: 78px;
    }

    @media ${sizes.G} {
        margin-bottom: none;
    }
`;
