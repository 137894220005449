import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ROLES, IUser } from '../../slices/userSlice';

import {
    BinItem,
    Header,
    Row,
    UsersContainer,
    UserId,
    FirstName,
    LastName,
    Email,
    Action,
    AccordionIcon,
    UserStatusColumn,
    SortIcon,
} from './styles';

import DropdownArrowGrey from '../../assets/icons/dropdown_arrow_grey.svg';

import { colours } from '../../constants/colours';

type onDeleteFunction = (id: number) => void;
type onClickColumnFunction = (column: string) => void;

export interface IProps {
    currentUserId: number;
    currentUserRole: string;
    users: IUser[];
    order?: boolean;
    orderBy?: string;
    onView: (user: IUser, childIndex?: number) => void;
    onDelete: onDeleteFunction;
    onClickColumn: onClickColumnFunction;
}
const ListUsers: React.FunctionComponent<IProps> = ({
    currentUserId,
    currentUserRole,
    users,
    order,
    orderBy,
    onView,
    onClickColumn,
    onDelete,
}) => {
    const { t } = useTranslation();

    const checkOrder = (field: string) => {
        if (orderBy !== field) {
            return true;
        } else {
            return order ?? true;
        }
    };

    const UserRow: React.FC<{ user: IUser; childIndex?: number }> = ({ user, childIndex }) => {
        const isChild = user.role === ROLES.CHILD;
        return (
            <>
                <UserId>{user.householdId}</UserId>
                <UserId>{user.childId}</UserId>
                {isChild ? (
                    <UserStatusColumn
                        droppedOut={user.droppedOut}
                        key={`droppedOut-${user.droppedOut}`}
                    />
                ) : (
                    <UserStatusColumn hideIcon />
                )}
                <FirstName key={`fn-${user.id}`}>{`${user.profile.firstName}`}</FirstName>
                <LastName key={`ln-${user.id}`}>{`${user.profile.lastName}`}</LastName>
                <Email key={`email-${user.id}`}>{user.email && user.email}</Email>
                <Action
                    pointer={true}
                    key={`action-${user.id}`}
                    onClick={() => {
                        onView(user, childIndex);
                    }}
                >
                    {t('admin.view')}
                </Action>
                <BinItem
                    hideIcon={currentUserRole !== ROLES.ADMIN || currentUserId === user.id}
                    key={`bin-${user.id}`}
                    onClick={() => onDelete(user.id)}
                />
            </>
        );
    };

    const HouseholdRow: React.FC<{ household: IUser }> = ({ household }) => {
        const [isHouseholdRowOpen, setIsHouseholdRowOpen] = useState(false);

        return (
            <>
                <Row>
                    {household.children && household.children.length ? (
                        <AccordionIcon
                            className={`${isHouseholdRowOpen ? 'open' : ''}`}
                            src={DropdownArrowGrey}
                            alt="see more details"
                            onClick={() => setIsHouseholdRowOpen(!isHouseholdRowOpen)}
                        />
                    ) : null}
                    <UserRow user={household} />
                </Row>

                {isHouseholdRowOpen &&
                    household.children?.map((child, index) => (
                        <Row style={{ backgroundColor: colours.lightGrey2 }}>
                            <UserRow user={child} childIndex={index + 1} />
                        </Row>
                    ))}
            </>
        );
    };

    return (
        <UsersContainer>
            <Header>
                <UserId onClick={() => onClickColumn('householdId')}>
                    {t('admin.householdId')}
                    <SortIcon
                        order={checkOrder('householdId')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </UserId>
                <UserId onClick={() => onClickColumn('childId')}>
                    {t('admin.childId')}
                    <SortIcon
                        order={checkOrder('childId')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </UserId>
                <UserStatusColumn onClick={() => onClickColumn('droppedOut')} hideIcon>
                    {t('admin.status')}{' '}
                    <SortIcon
                        order={checkOrder('droppedOut')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </UserStatusColumn>
                <FirstName onClick={() => onClickColumn('profile.firstName')}>
                    {t('admin.firstName')}{' '}
                    <SortIcon
                        order={checkOrder('profile.firstName')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </FirstName>
                <LastName onClick={() => onClickColumn('profile.lastName')}>
                    {t('admin.lastName')}{' '}
                    <SortIcon
                        order={checkOrder('profile.lastName')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </LastName>
                <Email onClick={() => onClickColumn('email')}>
                    {t('admin.email')}{' '}
                    <SortIcon
                        order={checkOrder('email')}
                        src={DropdownArrowGrey}
                        alt="Arrow icon"
                    />
                </Email>
                <Action color={colours.black}>{t('admin.actions')}</Action>
                <BinItem hideIcon={true}></BinItem>
            </Header>
            {users.map((user) => (
                <HouseholdRow key={user.id} household={user} />
            ))}
        </UsersContainer>
    );
};

export default ListUsers;
