import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailOutline, PhoneOutline } from '@styled-icons/evaicons-outline';

import LANGUAGES from '../../constants/languages';
import LINKS from '../../constants/links';
import { HelpInfoColumn, HelpInfoContainer, HelpInfoLines, HelpInfos } from './styles';

export const HelpInfo: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const getEmail = () => (language === LANGUAGES.EN ? LINKS.INFOEMAIL_EN : LINKS.INFOEMAIL_FR);

    return (
        <HelpInfoContainer>
            <HelpInfoColumn>{t('homepage.footer')}</HelpInfoColumn>
            <HelpInfoColumn border>
                <HelpInfoLines>
                    <EmailOutline size="20" />
                    <HelpInfos href={`mailto:${getEmail()}`} target="_blank">
                        {t('homepage.email')}
                    </HelpInfos>
                </HelpInfoLines>
                <HelpInfoLines>
                    <PhoneOutline size="20" />
                    <HelpInfos>{t('homepage.phone')}</HelpInfos>
                </HelpInfoLines>
            </HelpInfoColumn>
        </HelpInfoContainer>
    );
};
