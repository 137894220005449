import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../slices/authSlice';

import EncoreLogoFR from '../../assets/icons/encore_logo_min_fr.svg';
import EncoreLogoEN from '../../assets/icons/encore_logo_min_en.svg';
import LeftArrow from '../../assets/icons/left_arrow_icon.svg';

import {
    HeaderContainer,
    HeaderLogo,
    LogoutButton,
    PreviousButton,
    Title,
    LanguageSwitchContainer,
} from './styles';
import { LanguageSwitch } from '../../styles/common';
import LANGUAGES from '../../constants/languages';

export interface IProps {
    question: number;
    section: number;
    sectionName: string;
    sectionLength: number;
    onPrevious: () => void;
}

export const HeaderQuestion: React.FunctionComponent<IProps> = ({
    question,
    section,
    sectionName,
    sectionLength,
    onPrevious,
}) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language
    const history = useHistory();
    const dispatch = useDispatch();

    // Language switch
    const changeLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
    };

    // Changes logo according to language
    const encoreLogo = language === LANGUAGES.EN ? EncoreLogoEN : EncoreLogoFR;

    return (
        <HeaderContainer percentage={question / sectionLength}>
            {(question > 1 || section > 1) && (
                <PreviousButton onClick={onPrevious}>
                    <img src={LeftArrow} alt={t('footer.previous')} />
                </PreviousButton>
            )}
            <HeaderLogo
                onClick={() => {
                    history.push('/');
                }}
                src={encoreLogo}
                alt={t('homepage.logoAlt')}
            />
            <Title>
                {t('header.titleDesktop', {
                    sectionName: t(`header.section.${sectionName}`),
                })}
            </Title>
            <LogoutButton
                onClick={() => {
                    dispatch(logoutUser());
                }}
            >
                {t('header.saveAndContinueLater')}
            </LogoutButton>
            <LanguageSwitchContainer>
                <LanguageSwitch
                    type="button"
                    onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                >
                    {language === 'en' ? 'Fr' : 'En'}
                </LanguageSwitch>
            </LanguageSwitchContainer>
        </HeaderContainer>
    );
};
