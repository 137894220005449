import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import {
    LoginField,
    Error,
    Label,
    Fields,
    WelcomeTextContainer,
    LoginHint,
    Container,
} from './styles';

import { Button } from '../../components/Button';
import Loader from '../../components/Loader';

import HomeLayout from '../../layouts/home';

import { BACKEND_ROUTES } from '../../constants/routes';

export interface IParams {
    token: string;
}

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [updatedPassword, setUpdatedPassword] = useState<boolean>(false);

    const history = useHistory();

    const { t } = useTranslation();
    const params = useParams<IParams>();

    const isSignup = useRouteMatch('/signup/:token');

    const resetPassword = async () => {
        setError(password !== passwordConfirmation);
        if (password !== passwordConfirmation) return;
        setLoading(true);
        try {
            const { status } = await axios.post<{ data: any }>(BACKEND_ROUTES.RESET_PASSWORD, {
                password,
                token: params.token,
            });

            if (status === 204) {
                setUpdatedPassword(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const welcomeText = () => {
        if (updatedPassword) return t('login.passwordCreatedText');
        if (isSignup) return t('login.createPassword');
        return t('login.resetPassword');
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout leftSide>
                <Container>
                    <WelcomeTextContainer>{welcomeText()}</WelcomeTextContainer>
                    {!updatedPassword && (
                        <>
                            <Fields>
                                <Label htmlFor="password">{t('login.newPassword')}</Label>
                                <LoginField
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    autoCapitalize="none"
                                />
                            </Fields>
                            <Fields>
                                <Label htmlFor="passwordConfirmation">
                                    {t('login.newPasswordConfirmation')}
                                </Label>
                                <LoginField
                                    type="password"
                                    name="passwordConfirmation"
                                    id="passwordConfirmation"
                                    value={passwordConfirmation}
                                    onChange={(event) =>
                                        setPasswordConfirmation(event.target.value)
                                    }
                                    autoCapitalize="none"
                                />
                                {error && <Error>{t('login.matchPasswords')}</Error>}
                            </Fields>
                        </>
                    )}
                    {!updatedPassword && (
                        <>
                            <Button
                                label={
                                    isSignup ? t('login.createPassword') : t('login.resetPassword')
                                }
                                onClick={resetPassword}
                                justifySelf="center"
                            />
                            <LoginHint to="/login">{t('login.loginHint')}</LoginHint>
                        </>
                    )}
                    {updatedPassword && (
                        <Button
                            label={t('login.goToLogin')}
                            onClick={() => history.push('/login')}
                            justifySelf="center"
                        />
                    )}
                </Container>
            </HomeLayout>
        </>
    );
};

export default ResetPassword;
