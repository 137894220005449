import styled from 'styled-components';
import { Youtube } from '@styled-icons/fa-brands';

import { sizes } from '../../constants/mediaSizes';

import DaycarePhoto from '../../assets/images/encore_daycare_photo.jpg';
import { colours } from '../../constants/colours';

export const ThankYouContainer = styled.div`
    display: grid;
    place-items: center;
    height: 100%;

    @media ${sizes.XP} {
        margin: 0 20px;
    }

    @media ${sizes.M} {
        margin: 0;
    }
`;

export const Image = styled.img`
    max-width: 250px;

    @media ${sizes.XP} {
        padding: 10px;
    }

    @media ${sizes.M} {
        padding: 0;
    }
`;

export const Photo = styled.div`
    background-image: url(${DaycarePhoto});
    width: 500px;
    height: 500px;
    background-size: cover;
    background-position: right;
    border-radius: 100%;
`;

export const Text1 = styled.div`
    font-family: PoppinsRegular;
    font-size: 18px;
    text-align: center;

    @media ${sizes.M} {
        max-width: 620px;
    }
`;

export const Text2 = styled.div`
    font-family: PoppinsRegular;
    font-size: 18px;
    text-align: center;
`;

export const BloodSampleVideo = styled.a`
    display: grid;
    place-items: center;
    grid-template-columns: 75px auto;
    grid-gap: 10px;
    color: ${colours.white};
    background-color: ${colours.purple};
    padding: 15px;
    border-radius: 10px;
    font-family: 'NunitoBold';
    font-size: 20px;
    margin: 15px 0;
    text-decoration: none;
    white-space: pre-line;

    @media ${sizes.M} {
        grid-template-columns: auto auto;
    }
`;

export const LoginPage = styled.div`
    display: grid;
    place-items: center;
    grid-template-columns: 75px auto;
    grid-gap: 10px;
    color: ${colours.white};
    background-color: ${colours.purple};
    padding: 15px;
    border-radius: 10px;
    font-family: 'NunitoBold';
    font-size: 20px;
    margin: 15px 0;
    text-decoration: none;
    white-space: pre-line;

    @media ${sizes.M} {
        grid-template-columns: auto auto;
    }
`;

export const YouTubeIcon = styled(Youtube)`
    width: 100%;

    @media ${sizes.M} {
        height: 100%;
        width: unset;
    }
`;
