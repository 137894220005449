/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { Question } from '../../constants/questionTypes';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import { selectChild } from '../../slices/childSlice';

import { Textfield, Label, Container, TextArea } from './styles';
import {
    CustomCheckbox,
    HiddenCheckbox,
    OptionContainer,
    OptionLabel,
} from '../CheckboxQuestion/styles';

export interface IProps {
    question: number;
    text: string;
    index?: number;
    questionOptionId?: number;
    value?: Array<{ answer: string }> | string;
    options?: QuestionOptionType[];
    mask?: string;
}

const getDefaultValue = (value: Array<{ answer: string }> | string = ''): string => {
    if (Array.isArray(value)) {
        return value.length > 0 ? value[0].answer : '';
    }
    return value;
};

export const TextfieldQuestion: React.FC<IProps> = ({
    question,
    text,
    index,
    questionOptionId,
    value,
    mask = '',
}) => {
    const defaultAnswer = getDefaultValue(value);
    const [answer, setAnswer] = useState<string>(defaultAnswer);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const child = useSelector(selectChild);
    const schoolCPE = _.startCase(child?.schoolCpe);

    const { control } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });

    const handleChange = (question: number, answer: string) => {
        setAnswer(answer);
        dispatch(
            saveObject({
                question_id: question,
                answer,
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.text,
            }),
        );
    };

    useEffect(() => {
        if (index) {
            dispatch(
                saveObject({
                    question_id: question,
                    answer,
                    answer_offered_id: questionOptionId,
                    order: index,
                    type: Question.text,
                }),
            );
        }

        // Special rule for question where parent confirms School
        if (question === 112) {
            if (schoolCPE) {
                handleChange(question, schoolCPE);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <Controller
                render={() => (
                    <Container>
                        <Label>{t(`label.${text}`)}</Label>
                        {question === 112 && (
                            <Textfield
                                type={'text'}
                                mask={mask}
                                name={text}
                                value={answer}
                                onChange={(event) => handleChange(question, event.target.value)}
                            />
                        )}
                        {question === 2070 && (
                            <Textfield
                                type={'text'}
                                mask={mask}
                                name={text}
                                value={answer}
                                onChange={(event) => handleChange(question, event.target.value)}
                            />
                        )}
                        {question !== 112 && question !== 2070 && (
                            <TextArea
                                value={answer}
                                onChange={(event) => handleChange(question, event.target.value)}
                            />
                        )}
                    </Container>
                )}
                control={control}
                value={answer}
                name={text}
            />
            {/* Adds 'No comments' options for all text questions other than 112 (School/CPE) */}
            {question !== 112 && question !== 2070 && (
                <div>
                    <OptionContainer key={text}>
                        <HiddenCheckbox
                            id={text}
                            title={text}
                            type="checkbox"
                            name={text}
                            value={text}
                            onChange={() => handleChange(question, ' ')}
                        />
                        <CustomCheckbox
                            checked={answer === ' '}
                            onClick={() => handleChange(question, ' ')}
                        />
                        <OptionLabel htmlFor={text}>
                            {question === 5 ? t(`survey.noneFr`) : t(`survey.noComments`)}
                        </OptionLabel>
                    </OptionContainer>
                </div>
            )}
        </React.Fragment>
    );
};
