/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import { DashboardHeader } from '../../components/DashboardHeader';
import { AdminProfileModal } from '../../components/AdminProfileModal';
import { AdminPasswordModal } from '../../components/AdminPasswordModal';
import AdminDownloadHeader from '../../components/AdminDownloadHeader';
import { AdminCoordinatorInfoModal } from '../../components/AdminCoordinatorInfoModal';
import { AdminDeleteUserModal } from '../../components/AdminDeleteUserModal';
import { AdminParticipantInfoModal } from '../../components/AdminParticipantInfoModal';
import ListUsers from '../../components/ListUsers';
import Loader from '../../components/Loader';

// Redux
import { selectAuthenticatedUser } from '../../slices/authSlice';
import {
    IUser,
    IGetUserParams,
    getUsers,
    selectUsers,
    changePage,
    selectNextPage,
    selectPreviousPage,
    deleteUser,
    selectIsDeleteUserSuccess,
    selectError,
} from '../../slices/userSlice';

// Hooks
import { useStateDebounced } from '../../hooks';

// Styles
import {
    ButtonPage,
    ListHeader,
    SearchInput,
    TotalRecords,
    RadioButton,
    RadioContainer,
} from './styles';
import { DashboardPageContainer } from '../../styles/common';
import { isUserAdminOrCoordinator } from '../../utils/roles-check';

const Admin: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);

    const [listRole, setListRole] = useState<IGetUserParams['listRole']>('households');
    const [order, setOrder] = useState<boolean>(true);

    const [queryParams, setQueryParams] = useState<IGetUserParams>({
        searchTerm: '',
        listRole,
    });
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useStateDebounced(search, 400);

    const [participantInfoModalOpen, setParticipantInfoModalOpen] = useState<boolean>(false);
    const [targetParticipant, setTargetParticipant] = useState<{
        userId: IUser['id'];
        childIndex?: number;
    }>();

    const [isAdminCoordinatorInfoModalOpen, setIsAdminCoordinatorInfoModalOpen] =
        useState<boolean>(false);
    const [targetAdminOrCoordinator, setTargetAdminOrCoordinator] = useState<IUser>();

    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const nextPage = useSelector(selectNextPage);
    const previousPage = useSelector(selectPreviousPage);

    const [userToDelete, setUserToDelete] = useState<IUser | null>();
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const isDeleteSuccess = useSelector(selectIsDeleteUserSuccess);
    const userError = useSelector(selectError);
    const usersList = useSelector(selectUsers);

    const userId = authenticatedUser?.id;
    const userName = authenticatedUser?.profile?.firstName;
    const userRole = authenticatedUser?.role;

    const notifyDeletedUser = () =>
        toast('User deleted successfully', {
            type: toast.TYPE.SUCCESS,
        });
    const notifyDeletedUserError = () =>
        toast('Could not delete user', {
            type: toast.TYPE.ERROR,
        });

    const onViewUser = (user: IUser, childIndex?: number) => {
        if (isUserAdminOrCoordinator(user)) {
            setTargetAdminOrCoordinator(user);
        } else {
            setTargetParticipant({ userId: user.id, childIndex });
            setParticipantInfoModalOpen(true);
        }
    };

    const onCloseParticipantInfoModal = () => {
        setParticipantInfoModalOpen(false);
        setTargetParticipant(undefined);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListRole(event.target.value as IGetUserParams['listRole']);
    };

    const onClickPreviousPage = () => {
        if (previousPage) {
            dispatch(changePage(false));
        }
    };

    const onClickNextPage = () => {
        if (nextPage) {
            dispatch(changePage(true));
        }
    };

    useEffect(() => {
        dispatch(getUsers(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        setQueryParams({
            ...queryParams,
            searchTerm: debouncedSearch,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch]);

    useEffect(() => {
        if (isDeletingUser) {
            if (isDeleteSuccess) {
                dispatch(getUsers(queryParams));
                notifyDeletedUser();
                setUserToDelete(null);
                setIsDeletingUser(false);
            } else if (userError) {
                console.error('Failed to delete user');
                notifyDeletedUserError();
            }
        }
    }, [dispatch, isDeleteSuccess, isDeletingUser, queryParams, userError]);

    useEffect(() => {
        if (targetAdminOrCoordinator) {
            setIsAdminCoordinatorInfoModalOpen(true);
        }
    }, [targetAdminOrCoordinator]);

    useEffect(() => {
        if (!isAdminCoordinatorInfoModalOpen) {
            setTargetAdminOrCoordinator(undefined);
        }
    }, [isAdminCoordinatorInfoModalOpen]);

    useEffect(() => {
        setQueryParams({
            ...queryParams,
            listRole,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRole]);

    if (!authenticatedUser) {
        return <Loader />;
    }

    const openDeleteModal = (userIdToDelete: number) => {
        /* eslint-disable no-param-reassign */
        const userFound = usersList.reduce((result: IUser | null, user: IUser) => {
            if (user.id === userIdToDelete) {
                result = user;
            }
            const foundChild = user.children?.find((child) => child.id === userIdToDelete);
            if (foundChild) {
                result = foundChild;
            }
            return result;
        }, null);
        /* eslint-enable no-param-reassign */
        setUserToDelete(userFound);
    };

    const handleDeleteUser = (id: number) => {
        setIsDeletingUser(true);
        dispatch(deleteUser(id));
    };

    const orderByColumn = (field: string) => {
        setQueryParams({
            ...queryParams,
            orderBy: field,
            order,
        });
        setOrder(!order);
    };

    return (
        <DashboardPageContainer>
            <Helmet>
                <title>{t('admin.helmet')}</title>
            </Helmet>
            {profileModalOpen && <AdminProfileModal onCloseButton={setProfileModalOpen} />}
            {passwordModalOpen && <AdminPasswordModal onCloseButton={setPasswordModalOpen} />}
            {isAdminCoordinatorInfoModalOpen && (
                <AdminCoordinatorInfoModal
                    onCloseButton={setIsAdminCoordinatorInfoModalOpen}
                    user={targetAdminOrCoordinator}
                />
            )}
            {participantInfoModalOpen && targetParticipant && (
                <AdminParticipantInfoModal
                    onCloseButton={onCloseParticipantInfoModal}
                    participantId={targetParticipant.userId}
                    childIndex={targetParticipant.childIndex}
                />
            )}
            {userToDelete && (
                <AdminDeleteUserModal
                    onCloseButton={() => setUserToDelete(null)}
                    user={userToDelete}
                    onDelete={handleDeleteUser}
                />
            )}
            <DashboardHeader
                userName={userName}
                role={userRole}
                profileModal={setProfileModalOpen}
                passwordModal={setPasswordModalOpen}
                createUserModal={setIsAdminCoordinatorInfoModalOpen}
            />
            <AdminDownloadHeader />
            <ListHeader>
                <SearchInput placeholder={t('search')} type="text" onChange={handleSearch} />
                <TotalRecords>
                    {t('admin.total')}: {usersList.length}
                </TotalRecords>
                <RadioContainer>
                    <label htmlFor="households">
                        <RadioButton
                            type="radio"
                            id="households"
                            name="role"
                            checked={listRole === 'households'}
                            onChange={handleRole}
                            value="households"
                        />
                        {t('admin.participant')}
                    </label>
                    <label htmlFor="admin">
                        <RadioButton
                            type="radio"
                            id="admin"
                            name="role"
                            checked={listRole === 'admins'}
                            onChange={handleRole}
                            value="admins"
                        />
                        {`${t('admin.admin')} / ${t('admin.coordinator')}`}
                    </label>
                </RadioContainer>
            </ListHeader>
            <div style={{ height: '100%', width: '100%' }}>
                <ToastContainer />
                {userId && userRole && usersList && usersList.length > 0 && (
                    <ListUsers
                        onClickColumn={orderByColumn}
                        currentUserRole={userRole}
                        currentUserId={userId}
                        users={usersList}
                        onDelete={openDeleteModal}
                        onView={onViewUser}
                        order={queryParams.order}
                        orderBy={queryParams.orderBy}
                    />
                )}
            </div>
            <div>
                <ButtonPage active={Boolean(previousPage)} onClick={onClickPreviousPage}>
                    Previous
                </ButtonPage>
                <ButtonPage active={Boolean(nextPage)} onClick={onClickNextPage}>
                    Next
                </ButtonPage>
            </div>
        </DashboardPageContainer>
    );
};

export default Admin;
