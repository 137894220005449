import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const HeaderContainer = styled.div<{ percentage: number }>`
    display: grid;
    grid-template-columns: auto auto;
    place-items: center;
    height: 100%;
    width: 100%;
    font-family: NunitoBold;
    font-size: 20px;
    position: sticky;
    top: 0;
    background-color: ${colours.white};
    z-index: 10;

    @media ${sizes.M} {
        grid-template-columns: min-content auto 14% min-content;
    }

    ::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: solid 4px ${colours.lightGrey};
        width: 100%;
    }

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: solid 4px ${colours.pink};
        width: ${(props) => props.percentage * 100}%;
        z-index: 0;
    }
`;

export const PreviousButton = styled.button`
    border: none;
    padding: 0;
    width: 24px;
    margin-left: 24px;

    @media ${sizes.M} {
        display: none;
    }
`;

export const HeaderLogo = styled.img`
    cursor: pointer;
    height: 50px;
    width: auto;
    max-width: none;
    margin-left: 15px;
    display: none;

    @media ${sizes.M} {
        display: block;
    }
`;

export const Title = styled.div`
    margin: 0 24px;
`;

export const LogoutButton = styled.div`
    color: ${colours.purple};
    font-size: 16px;
    cursor: pointer;
    display: none;
    background-color: ${colours.lightGrey};
    border-radius: 10px;
    border: 1px solid #a3adb6;
    padding-left: 10px;
    margin-right: 10px;

    @media ${sizes.M} {
        display: block;
    }

    &:hover {
        border: 1px solid #d91c5c;
    }
`;

export const LanguageSwitchContainer = styled.div`
    display: none;

    @media ${sizes.M} {
        display: block;
    }
`;
