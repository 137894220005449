import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    importUsers,
    selectImportLoading,
    selectImportSuccess,
    selectImportResult,
    selectImportError,
} from '../../slices/userSlice';

import { readFileAsync } from '../../utils/readFile';

import Loader from '../Loader';

// Styles
import { Modal, ModalContainer, Message, CloseButton, Title } from '../../styles/modal';
import {
    Container,
    InputFile,
    ImportButton,
    ImportText,
    ImportIcon,
    DownloadButton,
    DownloadMenu,
    DownloadMenuItem,
    PageTitle,
    DownloadText,
    Icon,
    Arrow,
} from './styles';

import CloseIcon from '../../assets/icons/close_icon.svg';
import DownloadIcon from '../../assets/icons/download_icon.svg';
import DownArrow from '../../assets/icons/dropdown_arrow_white.svg';

import { BACKEND_ROUTES } from '../../constants/routes';

const AdminDownloadHeader: React.FunctionComponent = () => {
    const [showDownloadMenu, setShowDownloadMenu] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isImportModalOpened, setIsImportModalOpened] = useState<boolean>(false);
    const importLoading = useSelector(selectImportLoading);
    const importSuccess = useSelector(selectImportSuccess);
    const importResult = useSelector(selectImportResult);
    const importError = useSelector(selectImportError);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const downloadUsers = async () => {
        return downloadCSV(BACKEND_ROUTES.DOWNLOAD_PARTICIPANTS_DATA, 'participants.csv');
    };

    const downloadQuestionsHouseholdsAndChildren = () => {
        return downloadCSV(
            BACKEND_ROUTES.DOWNLOAD_SURVEY_DATA_HOUSEHOLDS_AND_CHILDREN,
            'questionnaire_households_children.csv',
        );
    };

    const downloadQuestionsTeenagers = () => {
        return downloadCSV(
            BACKEND_ROUTES.DOWNLOAD_SURVEY_DATA_TEENAGERS,
            'questionnaire_teenagers.csv',
        );
    };

    const downloadCSV = async (url: string, fileName: string) => {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            responseType: 'blob',
        });
        const responseUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = responseUrl;
        // link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target?.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            try {
                const arrayBuffer: any = await readFileAsync(file);
                const base64 = arrayBuffer.split(',')[1];
                const fileData = {
                    base64,
                    filename: file.name,
                    mimetype: file.type,
                };
                dispatch(importUsers(fileData));
            } catch (error) {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    useEffect(() => {
        if (importLoading) {
            setIsImportModalOpened(true);
        }
    }, [importLoading, importSuccess, importError]);

    return (
        <>
            {isImportModalOpened && (
                <ModalContainer>
                    <Modal>
                        <CloseButton onClick={() => setIsImportModalOpened(false)}>
                            <img src={CloseIcon} alt="close" />
                        </CloseButton>
                        {importLoading && <Loader />}
                        {importSuccess && <Title>{t('admin.importSuccess')}</Title>}
                        {importResult && (
                            <Message>
                                {t('admin.importResult', {
                                    nbHouseholdsCreated: importResult.nbHouseholdsCreated,
                                    nbChildrenCreated: importResult.nbChildrenCreated,
                                })}
                            </Message>
                        )}
                        {importError?.translationKey && t(importError.translationKey)}
                    </Modal>
                </ModalContainer>
            )}
            <Container>
                <PageTitle>{t('household')}</PageTitle>
                <InputFile type="file" ref={fileInputRef} onChange={handleFileInputChange} />
                <ImportButton
                    onClick={() => {
                        if (fileInputRef?.current) {
                            fileInputRef.current.click();
                        }
                    }}
                >
                    <ImportIcon />
                    <ImportText>{t('admin.importParticipants')}</ImportText>
                </ImportButton>

                <DownloadButton onClick={() => setShowDownloadMenu((prevState) => !prevState)}>
                    <Icon src={DownloadIcon} />
                    <DownloadText>{t('admin.downloadButton')}</DownloadText>
                    <Arrow src={DownArrow} />
                </DownloadButton>
                {showDownloadMenu && (
                    <DownloadMenu>
                        <DownloadMenuItem aria-hidden="true" onClick={downloadUsers}>
                            {t('admin.downloadUsers')}
                        </DownloadMenuItem>
                        <DownloadMenuItem
                            aria-hidden="true"
                            onClick={downloadQuestionsHouseholdsAndChildren}
                        >
                            {t('admin.downloadQuestionsHouseholdsAndChildren')}
                        </DownloadMenuItem>
                        <DownloadMenuItem aria-hidden="true" onClick={downloadQuestionsTeenagers}>
                            {t('admin.downloadQuestionsTeenagers')}
                        </DownloadMenuItem>
                    </DownloadMenu>
                )}
            </Container>
        </>
    );
};

export default AdminDownloadHeader;
