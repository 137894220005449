import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { selectAuthenticatedUser } from '../../slices/authSlice';
import { updateUser } from '../../slices/userSlice';

import CloseIcon from '../../assets/icons/close_icon.svg';

import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';
import { FieldError } from '../../styles/common';

export interface IProps {
    onCloseButton: (close: boolean) => void;
    viewMode?: boolean;
}

export const AdminProfileModal: React.FunctionComponent<IProps> = ({ onCloseButton, viewMode }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectAuthenticatedUser);

    const [firstName, setFirstName] = useState<string>(
        user?.profile.firstName ? user.profile.firstName : '',
    );
    const [lastName, setLastName] = useState<string>(
        user?.profile.lastName ? user.profile.lastName : '',
    );
    const [email, setEmail] = useState<string>(user ? user.email : '');
    const [errorMail, setErrorMail] = useState<boolean>(false);

    const sendUpdate = () => {
        if (user) {
            const values = {
                id: user.id,
                email,
                profile: {
                    id: user.profile.id,
                    firstName,
                    lastName,
                    postalCode: user.profile.postalCode,
                    firstAddressLine: user.profile.firstAddressLine,
                    secondAddressLine: user.profile.secondAddressLine,
                    city: user.profile.city,
                    province: user.profile.province,
                },
            };
            if (!errorMail) {
                dispatch(updateUser(values));
                onCloseButton(false);
            }
        }
    };

    return (
        <ModalContainer>
            <Modal>
                <CloseButton onClick={() => onCloseButton(false)}>
                    <img src={CloseIcon} alt="close" />
                </CloseButton>
                <Title>{t('admin.yourProfile')}</Title>
                <FieldContainer>
                    <Label>{t('profile.firstName')}</Label>
                    <Field
                        type="text"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    ></Field>
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('profile.lastName')}</Label>
                    <Field
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    ></Field>
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('profile.email')}</Label>
                    <Field
                        type="email"
                        value={`${email}`.toLowerCase()}
                        onBlur={() => setErrorMail(!validator.isEmail(email))}
                        onChange={(event) => {
                            setEmail(`${event.target.value}`.toLowerCase());
                        }}
                        autoCapitalize="none"
                    ></Field>
                    {errorMail && <FieldError>{t('profile.validEmail')}</FieldError>}
                </FieldContainer>
                <ButtonsContainer>
                    <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                    {!viewMode && <Send onClick={sendUpdate}>{t('admin.send')}</Send>}
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
