import React from 'react';
import { useTranslation } from 'react-i18next';

import EncoreLogoFR from '../../assets/icons/encore_logo_fr.svg';
import EncoreLogoEN from '../../assets/icons/encore_logo_en.svg';
import Background from '../../assets/images/encore_kids_background.jpg';

import {
    Container,
    CenterContainer,
    RightSide,
    ContactUs,
    LogoContainer,
    LeftSide,
    Image,
} from './styles';
import { Logo, LanguageSwitch } from '../../styles/common';
import LANGUAGES from '../../constants/languages';

type Props = {
    leftSide?: boolean;
    footerText?: string;
};

const HomeLayout: React.FC<Props> = ({ children, leftSide = false, footerText }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const changeLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
    };

    // Changes logo according to language
    const encoreLogo = language === LANGUAGES.EN ? EncoreLogoEN : EncoreLogoFR;

    return (
        <Container leftSide={leftSide}>
            {leftSide && (
                <LeftSide>
                    <Image src={Background} alt="background" />
                </LeftSide>
            )}
            <RightSide>
                <LanguageSwitch
                    type="button"
                    onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                >
                    {language === 'en' ? 'Fr' : 'En'}
                </LanguageSwitch>
                <LogoContainer>
                    <Logo src={encoreLogo} alt={t('homepage.logoAlt')} />
                </LogoContainer>
                <CenterContainer>
                    {children}
                    {footerText ? <ContactUs>{footerText}</ContactUs> : null}
                </CenterContainer>
            </RightSide>
        </Container>
    );
};

export default HomeLayout;
