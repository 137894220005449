import { IUser } from '../slices/userSlice';

import ROLES from '../constants/roles';

export const isUserAdminOrCoordinator = (user: IUser) =>
    user.role === ROLES.ADMIN || user.role === ROLES.COORDINATOR;

export const isUserAdmin = (user: IUser) => user.role === ROLES.ADMIN;

export const isUserCoordinator = (user: IUser) => user.role === ROLES.COORDINATOR;

export const isUserHousehold = (user: IUser) => user.role === ROLES.HOUSEHOLD;
