import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectAuthenticatedUser } from './slices/authSlice';
import { ROUTES } from './constants/routes';

const PrivateRoute: React.FC<RouteProps & { isAdminRoute?: boolean }> = ({
    children,
    isAdminRoute,
    ...rest
}) => {
    const user = useSelector(selectAuthenticatedUser);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                user.role === 'admin' || user.role === 'coordinator'
                                    ? ROUTES.ADMIN
                                    : ROUTES.PARENT_DASHBOARD,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
