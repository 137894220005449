import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuthenticatedUser } from '../../slices/authSlice';
import {
    IUser,
    createUser,
    selectCreatedUser,
    selectIsUpdateUserSuccess,
    selectError,
    clearUser,
    updateUser,
    ROLES,
} from '../../slices/userSlice';

import { isUserAdmin } from '../../utils/roles-check';
import { validateFirstName, validateLastName, validateEmail } from '../../utils/validation';

import { RadioOption } from '../RadioOption';
import Loader from '../Loader';

import CloseIcon from '../../assets/icons/close_icon.svg';
import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';
import { FieldError, RadioGroup } from '../../styles/common';

export interface IProps {
    user?: IUser;
    onCloseButton: (close: boolean) => void;
}

export const AdminCoordinatorInfoModal: React.FunctionComponent<IProps> = ({
    user,
    onCloseButton,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const authenticatedUser = useSelector(selectAuthenticatedUser);

    const [firstName, setFirstName] = useState(user?.profile.firstName ?? '');
    const [lastName, setLastName] = useState(user?.profile.lastName ?? '');
    const [email, setEmail] = useState(user?.email ?? '');
    const [role, setRole] = useState(user?.role ?? 'admin');
    const [invalidFirstName, setFirstNameInvalid] = useState(false);
    const [invalidLastName, setLastNameInvalid] = useState(false);
    const [invalidEmail, setEmailInvalid] = useState(false);

    const isFirstNameValid = (): boolean => validateFirstName(firstName);
    const isLastNameValid = (): boolean => validateLastName(lastName);
    const isEmailValid = (): boolean => validateEmail(email);

    const creationMode = !user;
    const [editMode, setEditMode] = useState(creationMode || false);

    const createdUser = useSelector(selectCreatedUser);
    const isUpdateUserSuccess = useSelector(selectIsUpdateUserSuccess);
    const userError = useSelector(selectError);

    const rolesOptions = [
        { label: t('admin.adminOrCoordinatorModal.titleAdministrator'), value: ROLES.ADMIN },
        { label: t('admin.adminOrCoordinatorModal.titleCoordinator'), value: ROLES.COORDINATOR },
    ];

    const isFormValid = (): boolean => {
        return isFirstNameValid() && isLastNameValid() && isEmailValid();
    };

    const onSave = () => {
        if (!isFormValid()) {
            return;
        }

        if (creationMode) {
            dispatch(
                createUser({
                    householdId: null,
                    email,
                    role,
                    profile: {
                        firstName,
                        lastName,
                    },
                }),
            );
        } else if (user) {
            dispatch(
                updateUser({
                    ...user,
                    email,
                    role,
                    profile: {
                        ...user.profile,
                        firstName,
                        lastName,
                    },
                }),
            );
        }
    };

    const handleRoleChange = (title: string) => {
        setRole(title);
    };

    // we've just created the user so let's clear it and exit
    if (createdUser || isUpdateUserSuccess) {
        dispatch(clearUser());
        onCloseButton(false);
    }

    const getButtons = (): JSX.Element =>
        editMode ? (
            <ButtonsContainer>
                <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                <Send onClick={onSave}>{t('admin.send')}</Send>
            </ButtonsContainer>
        ) : (
            <ButtonsContainer>
                <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                <Send
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    {t('admin.edit')}
                </Send>
            </ButtonsContainer>
        );

    if (!authenticatedUser) {
        return <Loader />;
    }

    return (
        <ModalContainer>
            <Modal>
                <CloseButton onClick={() => onCloseButton(false)}>
                    <img src={CloseIcon} alt="close" />
                </CloseButton>
                <Title>
                    {creationMode
                        ? t('admin.adminOrCoordinatorModal.createNewUser')
                        : t('admin.adminOrCoordinatorModal.user')}
                </Title>
                <FieldContainer>
                    <Label>{t('admin.adminOrCoordinatorModal.firstName')}</Label>
                    <Field
                        type="text"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        onBlur={() => setFirstNameInvalid(!isFirstNameValid())}
                        disabled={!editMode}
                    ></Field>
                    {invalidFirstName && (
                        <FieldError>
                            {t('admin.adminOrCoordinatorModal.invalidFirstName')}
                        </FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.adminOrCoordinatorModal.lastName')}</Label>
                    <Field
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        onBlur={() => setLastNameInvalid(!isLastNameValid())}
                        disabled={!editMode}
                    ></Field>
                    {invalidLastName && (
                        <FieldError>
                            {t('admin.adminOrCoordinatorModal.invalidLastName')}
                        </FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.adminOrCoordinatorModal.email')}</Label>
                    <Field
                        type="email"
                        value={`${email}`.toLowerCase()}
                        onChange={(event) => {
                            setEmail(`${event.target.value}`.toLowerCase());
                        }}
                        onBlur={() => setEmailInvalid(!isEmailValid())}
                        disabled={!editMode}
                        autoCapitalize="none"
                    ></Field>
                    {invalidEmail && (
                        <FieldError>{t('admin.adminOrCoordinatorModal.invalidEmail')}</FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.adminOrCoordinatorModal.role')}</Label>
                    {editMode ? (
                        <RadioGroup>
                            {rolesOptions.map(({ label, value }) => (
                                <RadioOption
                                    key={value}
                                    handleChange={() => handleRoleChange(value)}
                                    name={value}
                                    id={value}
                                    value={value}
                                    text={label}
                                    checked={value === role}
                                />
                            ))}
                        </RadioGroup>
                    ) : (
                        <Field
                            value={
                                (
                                    rolesOptions.find((option) => option.value === user?.role) ??
                                    rolesOptions[1]
                                ).label
                            }
                            disabled
                        />
                    )}
                </FieldContainer>
                <FieldError>{userError}</FieldError>
                {isUserAdmin(authenticatedUser) && getButtons()}
            </Modal>
        </ModalContainer>
    );
};
