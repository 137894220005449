import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IUser, IUserProfile } from './userSlice';

export interface IChild {
    id?: IUser['id'];
    householdId?: IUser['householdId'];
    firstWaveDate?: IUser['firstWaveDate'];
    firstName?: IUserProfile['firstName'];
    schoolCpe?: IUserProfile['schoolCpe'];
}

export interface IChildState {
    child: IChild | null;
}

export const initialState: IChildState = {
    child: null,
};

export const setChild = createAsyncThunk('set/child', (child: IChild, { dispatch }) => {
    dispatch(setChildInfoAction(child));
});

export const slice = createSlice({
    name: 'child',
    initialState,
    reducers: {
        setChildInfoAction(state, action) {
            state.child = action.payload;
        },
        clear(state) {
            state.child = null;
        },
    },
});

export const selectChild = (state: RootState) => state.child.child;

export const { setChildInfoAction, clear } = slice.actions;

export default slice.reducer;
