import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalContainer, Cancel, Send, Message, ButtonsContainer } from '../../styles/modal';

import { IUser } from '../../slices/userSlice';

type onDeleteFunction = (id: number) => void;
type onCloseButtonFunction = (close: boolean) => void;

export interface IProps {
    onCloseButton: onCloseButtonFunction;
    onDelete: onDeleteFunction;
    user: IUser;
}

export const AdminDeleteUserModal: React.FunctionComponent<IProps> = ({
    onCloseButton,
    onDelete,
    user,
}) => {
    const { t } = useTranslation();

    return (
        <ModalContainer>
            <Modal>
                <Message>
                    {t('admin.deleteMessage', {
                        firstName: user.profile.firstName,
                        secondName: user.profile.lastName,
                    })}
                </Message>
                <ButtonsContainer>
                    <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                    <Send onClick={() => onDelete(user.id)}>{t('admin.delete')}</Send>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
