/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import Loader from '../Loader';

import { CustomRadio, HiddenRadio } from '../RadioQuestion/styles';
import {
    BorderedRow,
    DownloadPDF,
    Question,
    QuestionContainer,
    Table,
    Column,
    Row,
    OptionContainer,
    OptionLabel,
    Mobile,
} from './styles';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';

import LANGUAGES from '../../constants/languages';

// Forms
import ConsentFormPDF_EN from '../../assets/EnCORE_Consent_En.pdf';
import ConsentFormPDF_FR from '../../assets/EnCORE_Consentement_Fr.pdf';

export interface IProps {
    question: number;
    text: string;
    options?: QuestionOptionType[];
}

export const MultiRadioQuestion: React.FunctionComponent<IProps> = ({
    question,
    text,
    options,
}) => {
    const [answers, setAnswers] = useState<string[][]>([]);
    const [showLastQuestion, setShowLastQuestion] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const getChildConsentDownload = () => {
        if (language === LANGUAGES.EN) {
            return ConsentFormPDF_EN;
        } else {
            return ConsentFormPDF_FR;
        }
    };

    const renderDownloadConsentPdf = (question: number) => {
        if (question === 1) {
            return (
                <DownloadPDF to={getChildConsentDownload()} target="_blank" download>
                    {t('consent.downloadPDF')}
                </DownloadPDF>
            );
        }
    };

    const handleChange = (internalQuestion: string, answer: string, id: number, order: number) => {
        const newAnswersArray = [...answers];
        const thisAnswer = newAnswersArray.filter(
            (thisQuestion) => thisQuestion[0] === internalQuestion,
        );

        if (thisAnswer.length > 0) {
            const thisAnswerIndex = newAnswersArray.findIndex(
                (thisQuestion) => thisQuestion[0] === thisAnswer[0][0],
            );
            newAnswersArray.splice(thisAnswerIndex, 1);
        }

        newAnswersArray.push([internalQuestion, answer]);
        setAnswers(newAnswersArray);

        // Checks if there is any answer different than "Not at all" and should not check the options from the last answer
        const isThereDaysInAnswers =
            newAnswersArray.findIndex(
                (option: any) => option[1] !== 'notAtAll' && option[0] !== 'howDifficult',
            ) !== -1;

        setShowLastQuestion(isThereDaysInAnswers);

        dispatch(
            saveObject({
                answer_offered_id: id,
                type: 'radio',
                question_id: question,
                order,
            }),
        );
    };

    useEffect(() => {
        const selectedOptions: any = [];
        const newAnswersArray = options
            ?.map((option) => {
                const selectedChildIndex = option.question_child.findIndex(
                    (child) => child.selected.length > 0,
                );
                if (selectedChildIndex !== -1) {
                    selectedOptions.push(option.question_child[selectedChildIndex]);
                    return [option.text, option.question_child[selectedChildIndex].text];
                }
            })
            .filter((elm) => elm);

        for (const answer of selectedOptions) {
            const { selected } = answer;
            dispatch(
                saveObject({
                    answer_offered_id: selected[0].answerOfferedId,
                    type: 'radio',
                    question_id: question,
                    user_id: answer.selected[0].userId,
                    order: selected[0].order,
                }),
            );
        }

        // Checks if there is any answer different than "Not at all" and should not check the options from the last answer
        if (newAnswersArray) {
            const isThereDaysInAnswers =
                newAnswersArray.findIndex(
                    (option: any) =>
                        option && option[1] !== 'notAtAll' && option[0] !== 'howDifficult',
                ) !== -1;
            setShowLastQuestion(isThereDaysInAnswers);
        }

        if (newAnswersArray && newAnswersArray.length > 0)
            setAnswers(newAnswersArray as string[][]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const getChecked = (internalQuestion: string, answer: string) => {
        const thisAnswer = answers.filter((thisQuestion) => thisQuestion[0] === internalQuestion);

        if (thisAnswer.length > 0) {
            return thisAnswer[0][0] === internalQuestion && thisAnswer[0][1] === answer;
        }

        return false;
    };

    if (!options) {
        return <Loader />;
    }

    const isQuestionMentalHealth = question === 64;
    const isHealth = question === 59;

    // If its question 64, we duplicated the code so we don't risk creating bugs on other questions.
    // We need to deduplicate this for the other forms though.
    if (isQuestionMentalHealth) {
        return (
            <>
                <Table>
                    <ol type="A">
                        <Row>
                            <Column></Column>
                            {options[0].question_child.map((answer) => (
                                <Column>{t(`survey.${answer.text}`)}</Column>
                            ))}
                        </Row>
                        {options.map((question, optionIndex) => {
                            const showQuestion =
                                question.text !== 'howDifficult' || showLastQuestion;
                            return (
                                <>
                                    {question.text === 'howDifficult' && showLastQuestion && (
                                        <BorderedRow>
                                            <Column></Column>
                                            {options[optionIndex].question_child.map((answer) => (
                                                <Column>{t(`survey.${answer.text}`)}</Column>
                                            ))}
                                        </BorderedRow>
                                    )}
                                    {showQuestion && (
                                        <Row>
                                            <QuestionContainer>
                                                <Question>{t(`survey.${question.text}`)}</Question>
                                            </QuestionContainer>
                                            {question.question_child.map((option, i) => {
                                                return (
                                                    <td>
                                                        <OptionContainer key={option.text}>
                                                            <CustomRadio
                                                                checked={getChecked(
                                                                    question.text,
                                                                    option.text,
                                                                )}
                                                                onClick={() =>
                                                                    handleChange(
                                                                        question.text,
                                                                        option.text,
                                                                        option.id,
                                                                        optionIndex,
                                                                    )
                                                                }
                                                            />
                                                            <HiddenRadio
                                                                type="radio"
                                                                name={question.text}
                                                                id={option.text}
                                                                value={option.text}
                                                                onChange={() =>
                                                                    handleChange(
                                                                        question.text,
                                                                        option.text,
                                                                        option.id,
                                                                        optionIndex,
                                                                    )
                                                                }
                                                            />
                                                            <OptionLabel htmlFor={option.text}>
                                                                {t(`survey.${option.text}`)}
                                                            </OptionLabel>
                                                        </OptionContainer>
                                                    </td>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </>
                            );
                        })}
                    </ol>
                </Table>
                <Mobile>
                    <ol type="A">
                        {options.map((question, optionIndex) => {
                            const showQuestion =
                                question.text !== 'howDifficult' || showLastQuestion;

                            return (
                                showQuestion && (
                                    <Question>
                                        {t(`survey.${question.text}`)}
                                        {question.question_child.map((option, i) => {
                                            return (
                                                <OptionContainer key={option.text}>
                                                    <CustomRadio
                                                        checked={getChecked(
                                                            question.text,
                                                            option.text,
                                                        )}
                                                        onClick={() =>
                                                            handleChange(
                                                                question.text,
                                                                option.text,
                                                                option.id,
                                                                optionIndex,
                                                            )
                                                        }
                                                    />
                                                    <HiddenRadio
                                                        type="radio"
                                                        name={question.text}
                                                        id={option.text}
                                                        value={option.text}
                                                        onChange={() =>
                                                            handleChange(
                                                                question.text,
                                                                option.text,
                                                                option.id,
                                                                optionIndex,
                                                            )
                                                        }
                                                    />
                                                    <OptionLabel htmlFor={option.text}>
                                                        {t(`survey.${option.text}`)}
                                                    </OptionLabel>
                                                </OptionContainer>
                                            );
                                        })}
                                    </Question>
                                )
                            );
                        })}
                    </ol>
                </Mobile>
            </>
        );
    }

    // If its question 59, we duplicated the code so we don't risk creating bugs on other questions.
    // We need to deduplicate this for the other forms though.
    if (isHealth) {
        return (
            <>
                <Table>
                    <ol type="A">
                        {options.map((question, optionIndex) => (
                            <>
                                <Row>
                                    <Column></Column>
                                    {options[optionIndex].question_child.map((answer) => (
                                        <Column minWidth="30px">
                                            {t(`survey.${answer.text}`)}
                                        </Column>
                                    ))}
                                </Row>
                                <Row>
                                    <QuestionContainer>
                                        <Question>{t(`survey.${question.text}`)}</Question>
                                    </QuestionContainer>
                                    {question.question_child.map((option, i) => {
                                        return (
                                            <td>
                                                <OptionContainer key={option.text}>
                                                    <CustomRadio
                                                        checked={getChecked(
                                                            question.text,
                                                            option.text,
                                                        )}
                                                        onClick={() =>
                                                            handleChange(
                                                                question.text,
                                                                option.text,
                                                                option.id,
                                                                optionIndex,
                                                            )
                                                        }
                                                    />
                                                    <HiddenRadio
                                                        type="radio"
                                                        name={question.text}
                                                        id={option.text}
                                                        value={option.text}
                                                        onChange={() =>
                                                            handleChange(
                                                                question.text,
                                                                option.text,
                                                                option.id,
                                                                optionIndex,
                                                            )
                                                        }
                                                    />
                                                </OptionContainer>
                                            </td>
                                        );
                                    })}
                                </Row>
                                <Row></Row>
                            </>
                        ))}
                    </ol>
                </Table>
                <Mobile>
                    <ol type="A">
                        {options.map((question, optionIndex) => (
                            <Question>
                                {t(`survey.${question.text}`)}
                                {question.question_child.map((option, i) => {
                                    return (
                                        <OptionContainer key={option.text}>
                                            <CustomRadio
                                                checked={getChecked(question.text, option.text)}
                                                onClick={() =>
                                                    handleChange(
                                                        question.text,
                                                        option.text,
                                                        option.id,
                                                        optionIndex,
                                                    )
                                                }
                                            />
                                            <HiddenRadio
                                                type="radio"
                                                name={question.text}
                                                id={option.text}
                                                value={option.text}
                                                onChange={() =>
                                                    handleChange(
                                                        question.text,
                                                        option.text,
                                                        option.id,
                                                        optionIndex,
                                                    )
                                                }
                                            />
                                            <OptionLabel htmlFor={option.text}>
                                                {t(`survey.${option.text}`)}
                                            </OptionLabel>
                                        </OptionContainer>
                                    );
                                })}
                            </Question>
                        ))}
                    </ol>
                </Mobile>
            </>
        );
    }

    return (
        <>
            <>{renderDownloadConsentPdf(question)}</>
            <Table>
                <ol type="A">
                    <Row>
                        <Column></Column>
                        {options[0].question_child.map((answer) => (
                            <Column>{t(`survey.${answer.text}`)}</Column>
                        ))}
                    </Row>
                    {options.map((question, optionIndex) => (
                        <Row>
                            <QuestionContainer>
                                <Question>{t(`survey.${question.text}`)}</Question>
                            </QuestionContainer>
                            {question.question_child.map((option, i) => {
                                return (
                                    <td>
                                        <OptionContainer key={option.text}>
                                            <CustomRadio
                                                checked={getChecked(question.text, option.text)}
                                                onClick={() =>
                                                    handleChange(
                                                        question.text,
                                                        option.text,
                                                        option.id,
                                                        optionIndex,
                                                    )
                                                }
                                            />
                                            <HiddenRadio
                                                type="radio"
                                                name={question.text}
                                                id={option.text}
                                                value={option.text}
                                                onChange={() =>
                                                    handleChange(
                                                        question.text,
                                                        option.text,
                                                        option.id,
                                                        optionIndex,
                                                    )
                                                }
                                            />
                                        </OptionContainer>
                                    </td>
                                );
                            })}
                        </Row>
                    ))}
                </ol>
            </Table>
            <Mobile>
                <ol type="A">
                    {options.map((question, optionIndex) => (
                        <Question>
                            {t(`survey.${question.text}`)}
                            {question.question_child.map((option, i) => {
                                return (
                                    <OptionContainer key={option.text}>
                                        <CustomRadio
                                            checked={getChecked(question.text, option.text)}
                                            onClick={() =>
                                                handleChange(
                                                    question.text,
                                                    option.text,
                                                    option.id,
                                                    optionIndex,
                                                )
                                            }
                                        />
                                        <HiddenRadio
                                            type="radio"
                                            name={question.text}
                                            id={option.text}
                                            value={option.text}
                                            onChange={() =>
                                                handleChange(
                                                    question.text,
                                                    option.text,
                                                    option.id,
                                                    optionIndex,
                                                )
                                            }
                                        />
                                        <OptionLabel htmlFor={option.text}>
                                            {t(`survey.${option.text}`)}
                                        </OptionLabel>
                                    </OptionContainer>
                                );
                            })}
                        </Question>
                    ))}
                </ol>
            </Mobile>
        </>
    );
};
