import styled from 'styled-components';
import { UploadOutline } from '@styled-icons/evaicons-outline';

import { colours } from '../../constants/colours';

export const Container = styled.div`
    display: flex;
    margin: 0 15px;
    max-width: 1335px;
    flex-direction: row;
    align-self: center;
    position: relative;
`;

export const PageTitle = styled.div`
    flex: 1;
    font-family: 'NunitoRegular';
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 41px;
    text-transform: capitalize;
`;

export const DownloadButton = styled.div`
    background-color: ${colours.purple};
    height: 50px;
    align-self: flex-end;
    color: ${colours.white};
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    padding: 15px;
    cursor: pointer;
`;

export const InputFile = styled.input`
    display: none;
`;

export const ImportButton = styled(DownloadButton)`
    margin-right: 20px;
`;

export const DownloadMenu = styled.div`
    border: 2px solid ${colours.mediumGrey};
    display: grid;
    align-items: center;
    font-family: 'PoppinsRegular';
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 4px 4px ${colours.lightGrey};
    position: absolute;
    top: calc(100% + 20px);
    right: 0px;
    background-color: ${colours.white};
`;

export const DownloadMenuItem = styled.div`
    cursor: pointer;
    /* padding: 5px; */
    width: 100%;
    height: 100%;
    margin: 5px;
    align-items: center;
    display: flex;
`;

export const Icon = styled.img`
    width: 24px;
`;

export const Arrow = styled.img`
    width: 12px;
`;

export const DownloadText = styled.div`
    font-family: 'NunitoRegular';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    flex: 1;
    margin: 0 15px;
`;

export const ImportText = DownloadText;

export const ImportIcon = styled(UploadOutline)`
    width: 24px;
`;
