import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { ServerError } from '../types/error';

import { RootState } from '../store';

export interface IChildren {
    id: number;
    firstName: string;
    birthday: string;
    firstWaveDate: string;
    consentParticipate: string;
}

export interface IHousehold {
    id: number;
    firstName: string;
    lastName: string;
}

export interface IHouseholdInfo {
    household: IHousehold;
    children: IChildren[];
}

export interface IHouseholdState {
    householdInfo: IHouseholdInfo | null;
    error: ServerError;
    inProgress: boolean;
}

export const initialState: IHouseholdState = {
    householdInfo: null,
    error: null,
    inProgress: false,
};

export const clearHouseholdInfo = createAsyncThunk('get/householdInfo/clear', (_, { dispatch }) => {
    dispatch(clear());
});

export const getHouseholdInfo = createAsyncThunk(
    'get/householdInfo',
    async (_, { dispatch, rejectWithValue }) => {
        dispatch(setGetHouseholdInfoInProgress(true));

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios.get(`${BACKEND_ROUTES.HOUSEHOLD}`, config);

            dispatch(setGetHouseholdInfoAction(response.data));
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Household doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }

            dispatch(setGetHouseholdInfoErrorAction(errorMessage));
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const slice = createSlice({
    name: 'getHouseholdInfo',
    initialState,
    reducers: {
        setGetHouseholdInfoAction(state, action) {
            state.householdInfo = action.payload;
            state.error = null;
            state.inProgress = false;
        },
        setGetHouseholdInfoErrorAction(state, action) {
            state.householdInfo = null;
            state.error = action.payload;
            state.inProgress = false;
        },
        setGetHouseholdInfoInProgress(state, action) {
            state.householdInfo = null;
            state.error = null;
            state.inProgress = action.payload;
        },
        clear(state) {
            state.householdInfo = null;
            state.error = null;
            state.inProgress = false;
        },
    },
});

export const selectHousehold = (state: RootState) => state.household.householdInfo;
export const selectHouseholdInfoError = (state: RootState) => state.household.error;

export const {
    setGetHouseholdInfoAction,
    setGetHouseholdInfoInProgress,
    setGetHouseholdInfoErrorAction,
    clear,
} = slice.actions;

export default slice.reducer;
