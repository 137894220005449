import React from 'react';

import { OptionContainer, OptionLabel } from '../CheckboxQuestion/styles';
import { CustomRadio, HiddenRadio } from '../RadioQuestion/styles';

export interface IProps {
    checked: boolean;
    handleChange: () => void;
    name: string;
    id: string;
    value: string;
    text: string;
    disabled?: boolean;
}

export const RadioOption: React.FC<IProps> = ({
    checked,
    handleChange,
    name,
    id,
    value,
    text,
    disabled,
}) => {
    return (
        <OptionContainer>
            <CustomRadio
                checked={checked}
                onClick={() => !disabled && handleChange()}
                disabled={disabled}
            />
            <HiddenRadio
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={() => !disabled && handleChange}
                disabled={disabled}
            />
            <OptionLabel htmlFor={name} disabled={disabled}>
                {text}
            </OptionLabel>
        </OptionContainer>
    );
};

export default RadioOption;
