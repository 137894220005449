import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import SuccessIcon from '../../assets/icons/success.svg';

import { LoginField, Error, Label, Fields, WelcomeTextContainer, TextContainer } from './styles';

import { Button } from '../../components/Button';
import { HelpInfo } from '../../components/HelpInfo';

import HomeLayout from '../../layouts/home';

import {
    PasswordStatus,
    selectPasswordEmailStatus,
    sendCreatePasswordEmail,
    sendResetPasswordEmail,
} from '../../slices/authSlice';
import { Login, Subtitle, Subtitle2, Title } from '../home/styles';
import { ROUTES } from '../../constants/routes';

interface IProps {
    isFirstAccess?: boolean;
}

const SendPasswordReset: React.FC<IProps> = ({ isFirstAccess }) => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const passwordEmailStatus = useSelector(selectPasswordEmailStatus);

    const createPassword = () => {
        setError(!validator.isEmail(email));

        dispatch(sendCreatePasswordEmail({ email }));
    };

    const resetPassword = () => {
        setError(!validator.isEmail(email));

        dispatch(sendResetPasswordEmail({ email }));
    };

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout leftSide>
                {!isFirstAccess && (
                    <WelcomeTextContainer>{t('login.resetPassword')}</WelcomeTextContainer>
                )}
                {!isFirstAccess && passwordEmailStatus === PasswordStatus.ok && (
                    <TextContainer>
                        <img src={SuccessIcon} alt="email_sent_img" /> {t('login.resetLinkSent')}
                    </TextContainer>
                )}
                {!isFirstAccess && passwordEmailStatus !== PasswordStatus.ok && (
                    <TextContainer>{t('login.enterYourEmail')}</TextContainer>
                )}
                {isFirstAccess && passwordEmailStatus === PasswordStatus.ok && (
                    <TextContainer>
                        <img src={SuccessIcon} alt="email_sent_img" />{' '}
                        {t('login.welcomeResetLinkSent')}
                    </TextContainer>
                )}
                {isFirstAccess && passwordEmailStatus !== PasswordStatus.ok && (
                    <>
                        <Title>{t('homepage.title')}</Title>
                        <Subtitle>{t('homepage.subtitle1')}</Subtitle>
                        <Subtitle2>{t('homepage.subtitle2')}</Subtitle2>
                    </>
                )}
                {passwordEmailStatus !== PasswordStatus.ok && (
                    <>
                        <Fields>
                            <Label htmlFor="email">{t('profile.email')}</Label>
                            <LoginField
                                type="email"
                                name="email"
                                id="email"
                                value={`${email}`.toLowerCase()}
                                onChange={(event: { target: { value: string } }) =>
                                    setEmail(`${event.target.value}`.toLowerCase())
                                }
                                autoCapitalize="none"
                            />
                            {error && <Error>{t('profile.validEmail')}</Error>}
                        </Fields>
                        <Button
                            label={
                                isFirstAccess ? t('login.welcomeBackButton') : t('login.sendLink')
                            }
                            onClick={isFirstAccess ? createPassword : resetPassword}
                            justifySelf="center"
                        />
                    </>
                )}
                {isFirstAccess && passwordEmailStatus !== PasswordStatus.ok && (
                    <Login to={ROUTES.LOGIN} exact>
                        {t('homepage.login')}
                    </Login>
                )}
                <HelpInfo />
            </HomeLayout>
        </>
    );
};

export default SendPasswordReset;
