import styled from 'styled-components';

import { colours } from '../constants/colours';
import { sizes } from '../constants/mediaSizes';

export interface IModalProps {
    size?: 'large' | 'medium';
}

export const ModalContainer = styled.div`
    display: grid;
    position: fixed;
    z-index: 20;
    height: 90vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: ${colours.modalGrey};

    @media ${sizes.M} {
        place-items: center;
    }
`;

const handleModalSize = (size: IModalProps['size']) => {
    if (size === 'large') return '80%';
    if (size === 'medium') return '60%';
    return '80vw';
};

export const Modal = styled.div<IModalProps>`
    position: relative;
    display: grid;
    overflow: auto;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    border: 2px solid ${colours.mediumGrey};
    box-shadow: 0 4px 4px ${colours.dropShadow};
    padding: 10px;
    width: 80vw;

    @media ${sizes.M} {
        padding: 23px 41px 26px 41px;
        width: ${({ size }) => handleModalSize(size)};
        max-height: 80%;
    }
`;

export const CloseButton = styled.button`
    border: none;
    width: 100%;
    text-align: right;
    position: absolute;
    top: 18px;
    right: 20px;
`;

export const Title = styled.div`
    font-family: 'NunitoBold';
    font-size: 26px;
    color: ${colours.purple};
    width: 70%;
`;

export const Message = styled.div<{ center?: boolean }>`
    font-size: 20px;
    color: ${colours.black};
    white-space: pre-line;

    ${({ center }) =>
        center &&
        `
        text-align: center
        `}
`;

export const FieldContainer = styled.div<{ inline?: boolean }>`
    font-family: PoppinsRegular;
    margin-top: 20px;
    font-size: 14px;

    @media ${sizes.XG} {
        font-size: 16px;
    }

    ${({ inline }) =>
        inline &&
        `
            display: flex;
            align-items: flex-start;

            label {
                width: unset;
                margin-bottom: 0;
                margin-right: 3px;
            }

            input {
                width: unset;
            }`}
`;

export const Label = styled.label`
    width: 100%;
    color: ${colours.darkGrey};
    margin-bottom: 2px;
    line-height: 34px;
`;

export const Field = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 7px;
    border: 1px solid ${colours.mediumGrey};
    padding: 0 15px;
    line-height: 34px;

    &:disabled {
        border: none;
        padding: 0;
        height: auto;
    }
`;

export const ButtonsContainer = styled.div<{ center?: boolean }>`
    justify-self: end;
    margin-top: 5px;

    @media ${sizes.M} {
        margin-top: 40px;
    }

    ${({ center }) =>
        center &&
        `
        justify-self: center;
        text-align: center
        `}
`;

export const Cancel = styled.button`
    width: 126px;
    height: 44px;
    background-color: ${colours.white};
    color: ${colours.purple};
    border: none;
    border-radius: 10px;
    font-family: NunitoBold;
    transition: all 0.5s ease;
    font-size: 20px;

    &:hover {
        color: ${colours.purpleHover};
    }
`;

export const Send = styled.button`
    width: 126px;
    height: 44px;
    background-color: ${colours.purple};
    color: ${colours.white};
    border-radius: 10px;
    border: none;
    font-family: NunitoBold;
    font-size: 20px;
    transition: all 0.5s ease;

    &:hover {
        background-color: ${colours.purpleHover};
        cursor: pointer;
    }
`;

export const Warning = styled.div`
    color: ${colours.errorRed};
`;
