import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import { PaginatedPayload } from './common';
import { UserSurveyFormDataType } from './surveySlice';
import { ServerError } from '../types/error';

export const ROLES = {
    ADMIN: 'admin',
    COORDINATOR: 'coordinator',
    HOUSEHOLD: 'household',
    CHILD: 'child',
};

export interface IUser {
    id: number;
    householdId: number | null;
    childId: number | null;
    role:
        | typeof ROLES.ADMIN
        | typeof ROLES.COORDINATOR
        | typeof ROLES.HOUSEHOLD
        | typeof ROLES.CHILD;
    email: string;
    firstWaveDate: string;
    droppedOut: boolean;
    createdAt: string;
    onboardedAt: string;
    participatingToStudy: boolean;
    participatingToBloodSample: boolean;
    homeFingerPrick: string;
    testKitSent: boolean;
    sampleReceivedAtLab: string;
    labStatusResult: string;
    profileId?: number;
    profile: IUserProfile;
    children?: IUser[];
    survey?: {
        userId: number;
        surveyId: number;
        currentQuestionId: number | null;
        completedAt?: string | null;
    };
    hasAllChildrenWithSurvey?: boolean;
    hasChildrenParticipatingToStudy?: boolean;
    hasAllChildrenCompletedSurvey?: boolean;
}

export interface IUserFormData {
    id?: IUser['id'];
    householdId?: IUser['householdId'];
    childId?: IUser['childId'];
    role?: IUser['role'];
    email?: IUser['email'];
    password?: string;
    firstWaveDate?: IUser['firstWaveDate'];
    droppedOut?: IUser['droppedOut'];
    participatingToStudy?: IUser['participatingToStudy'];
    participatingToBloodSample?: IUser['participatingToBloodSample'];
    homeFingerPrick?: IUser['homeFingerPrick'];
    testKitSent?: IUser['testKitSent'];
    sampleReceivedAtLab?: IUser['sampleReceivedAtLab'];
    labStatusResult?: IUser['labStatusResult'];
    hasAllChildrenWithSurvey?: IUser['hasAllChildrenWithSurvey'];
    hasChildrenParticipatingToStudy?: IUser['hasChildrenParticipatingToStudy'];
    hasAllChildrenCompletedSurvey?: IUser['hasAllChildrenCompletedSurvey'];
    profile?: IUserProfileFormData;
    children?: IUserFormData[];
    survey?: UserSurveyFormDataType;
}

export interface IUserProfile {
    id: number;
    firstName: string;
    lastName: string;
    birthday: string;
    postalCode: string;
    firstAddressLine: string;
    secondAddressLine: string;
    city: string;
    province: string;
    language: string;
    phone: string;
    schoolCpe: string;
}

export interface IUserProfileFormData {
    id?: IUserProfile['id'];
    firstName?: IUserProfile['firstName'];
    lastName?: IUserProfile['lastName'];
    birthday?: IUserProfile['birthday'];
    postalCode?: IUserProfile['postalCode'];
    firstAddressLine?: IUserProfile['firstAddressLine'];
    secondAddressLine?: IUserProfile['secondAddressLine'];
    city?: IUserProfile['city'];
    province?: IUserProfile['province'];
    language?: IUserProfile['language'];
    phone?: IUserProfile['phone'];
    schoolCpe?: IUserProfile['schoolCpe'];
}
export interface IGetUserParams {
    searchTerm: string;
    listRole?: 'admins' | 'households';
    orderBy?: string;
    order?: boolean;
}

type UsersPayload = PaginatedPayload<IUser>;

type ImportUsersResult = { nbHouseholdsCreated: 'number'; nbChildrenCreated: 'number' };
export interface IUserState {
    users: IUser[];
    usersById: { [id: number]: IUser };
    error?: ServerError;
    nextPage?: string;
    previousPage?: string;
    loading: boolean;
    isGettingUser: boolean;
    isUpdatingUser: boolean;
    isUpdateUserSuccess: boolean;
    isDeleteUserSuccess: boolean;
    createdUser: IUser | null;
    importLoading: boolean;
    importError?: ServerError;
    importSuccess: boolean;
    importResult: ImportUsersResult | null;
}

export const initialState: IUserState = {
    users: [],
    usersById: {},
    loading: false,
    isGettingUser: false,
    isUpdatingUser: false,
    isUpdateUserSuccess: false,
    isDeleteUserSuccess: false,
    error: null,
    createdUser: null,
    importLoading: false,
    importError: null,
    importSuccess: false,
    importResult: null,
};

export const clearUser = createAsyncThunk('update/participantInfo/clear', (_, { dispatch }) => {
    dispatch(clear());
});

export const getUsers = createAsyncThunk<
    UsersPayload,
    IGetUserParams,
    { rejectValue: ServerError }
>('get/users', async ({ searchTerm, listRole, orderBy, order }, { rejectWithValue, dispatch }) => {
    try {
        let query = '';
        let fields = `&fields=[email,profile.firstName,profile.lastName]&match=any`;

        if (searchTerm !== '') {
            query += `&query=${searchTerm}`;
        }

        if (orderBy && typeof order !== 'undefined') {
            query += `&orderBy=${orderBy}&order=${order ? 'asc' : 'desc'}`;
        }

        // The search term is a number
        if (searchTerm !== '' && !Number.isNaN(Number(searchTerm))) {
            fields = `&fields=[householdId,children.childId]&match=exact`;
        }

        if (listRole) {
            query += `&listRole=${listRole}`;
        }

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }

        const response = await axios.get<UsersPayload>(
            `${BACKEND_ROUTES.USERS}?page=0&pageSize=5${fields}${query}`,
            config,
        );

        dispatch(setNextPage(response.data.next));
        dispatch(setPreviousPage(response.data.prev));

        if (response.data.data) {
            return { ...response.data };
        }
        return rejectWithValue({ message: 'No data returned' });
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue({ message: error.response.data });
        }
        return rejectWithValue({ message: error.message });
    }
});

export const changePage = createAsyncThunk<
    UsersPayload | { data: IUser[] },
    boolean,
    { rejectValue: ServerError; state: RootState }
>('get/users/nextPage', async (nextPage, { rejectWithValue, getState, dispatch }) => {
    try {
        const state = getState();

        let query;

        if (nextPage && state.user.nextPage) {
            query = `${BACKEND_ROUTES.BASE_URL}${state.user.nextPage}`;
        }

        if (!nextPage && state.user.previousPage) {
            query = `${BACKEND_ROUTES.BASE_URL}${state.user.previousPage}`;
        }

        if (!query) {
            return { data: state.user.users };
        }

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        const response = await axios.get<UsersPayload>(query, config);

        dispatch(setNextPage(response.data.next));
        dispatch(setPreviousPage(response.data.prev));

        if (response.data.data) {
            return { ...response.data };
        }
        return rejectWithValue({ message: 'No data returned' });
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue({ message: error.response.data });
        }
        return rejectWithValue({ message: error.message });
    }
});

export const getUser = createAsyncThunk<IUser, IUser['id'], { rejectValue: ServerError }>(
    'get/user',
    async (userId, { rejectWithValue }) => {
        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios.get<{ data?: IUser }>(
                `${BACKEND_ROUTES.USERS}/${userId}`,
                config,
            );
            if (response.data.data) {
                return response.data.data;
            }
            return rejectWithValue({ message: 'No data returned' });
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Participant doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const createUser = createAsyncThunk<IUser, IUserFormData, { rejectValue: ServerError }>(
    'create/user',
    async (userToCreate, { rejectWithValue }) => {
        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios.post<{ data?: IUser }>(
                BACKEND_ROUTES.USERS,
                userToCreate,
                config,
            );

            if (response.data.data) {
                return response.data.data;
            }
            return rejectWithValue({ message: 'Problem when create user' });
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 409) {
                errorMessage = 'User already exists';
            } else if (error.response?.status === 403) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 400) {
                errorMessage = 'Required fields missing';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const updateUser = createAsyncThunk<IUser, IUserFormData, { rejectValue: ServerError }>(
    'update/user',
    async (user: IUserFormData, { rejectWithValue, dispatch }) => {
        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }

        const userToUpdate = { ...user };
        delete userToUpdate.hasChildrenParticipatingToStudy;
        delete userToUpdate.hasAllChildrenWithSurvey;
        delete userToUpdate.hasAllChildrenCompletedSurvey;

        try {
            const response = await axios.put<{ data?: IUser }>(
                `${BACKEND_ROUTES.USERS}/${userToUpdate.id}`,
                userToUpdate,
                config,
            );

            if (response.data.data) {
                return response.data.data;
            }
            return rejectWithValue({ message: 'Problem when update user' });
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Participant doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const deleteUser = createAsyncThunk<IUser['id'], IUser['id'], { rejectValue: ServerError }>(
    'delete/user',
    async (userId, { rejectWithValue }) => {
        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }

        try {
            const response = await axios.delete<{ data?: IUser }>(
                `${BACKEND_ROUTES.USERS}/${userId}`,
                config,
            );

            if (response.status === 204) {
                return userId;
            }

            return rejectWithValue({ message: 'Problem when delete user' });
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Participant doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const importUsers = createAsyncThunk<
    ImportUsersResult,
    { base64: string; filename: string; mimetype: string },
    { rejectValue: ServerError }
>('import/users', async (fileData, { rejectWithValue }) => {
    const token: string | null = localStorage.getItem('token');
    const config: {
        headers?: {};
    } = {};
    if (token) {
        config.headers = {
            authorization: `Bearer ${token}`,
        };
    }

    try {
        const response = await axios.post<{ data?: ImportUsersResult }>(
            `${BACKEND_ROUTES.IMPORT_USERS}`,
            fileData,
            config,
        );

        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({ message: 'Problem when import users' });
    } catch (e) {
        const error = e as AxiosError<ServerError>;

        if (error.response?.data?.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.importCsv.unknownError',
        });
    }
});

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setNextPage(state, action: PayloadAction<string | undefined>) {
            state.nextPage = action.payload;
        },
        setPreviousPage(state, action: PayloadAction<string | undefined>) {
            state.previousPage = action.payload;
        },
        clear(state) {
            state.createdUser = null;
            state.error = null;
            state.loading = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUsers.fulfilled, (state, { payload }) => {
            state.usersById = {};
            if (payload.data) {
                state.users = payload.data;
                payload.data.forEach((item: IUser) => {
                    state.usersById[item.id] = item;
                });
            }
            state.loading = false;
        });
        builder.addCase(getUsers.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(changePage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(changePage.fulfilled, (state, { payload }) => {
            state.usersById = {};
            if (payload.data) {
                state.users = payload.data;
                payload.data.forEach((item: IUser) => {
                    state.usersById[item.id] = item;
                });
            }
            state.loading = false;
        });
        builder.addCase(changePage.rejected, (state, { payload }) => {
            state.loading = false;
            if (payload) {
                state.error = payload;
            }
        });
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state.loading = true;
            state.usersById[payload.id] = payload;
        });
        builder.addCase(getUser.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(createUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.users.push(payload);
            state.usersById[payload.id] = payload;
            state.createdUser = payload;
        });
        builder.addCase(createUser.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(importUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importLoading = true;
            state.importError = null;
        });
        builder.addCase(importUsers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.importSuccess = true;
            state.importLoading = false;
            state.importResult = payload;
        });
        builder.addCase(importUsers.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.importLoading = false;
            state.importError = payload;
        });
        builder.addCase(updateUser.pending, (state) => {
            state.loading = true;
            state.isUpdatingUser = true;
            state.error = null;
        });
        builder.addCase(updateUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.isUpdatingUser = false;
            state.isUpdateUserSuccess = true;
            state.usersById[payload.id] = payload;
        });
        builder.addCase(updateUser.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.isDeleteUserSuccess = true;
            state.users = state.users.filter((value) => {
                return value.id !== payload;
            });
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete state.usersById[payload];
        });
        builder.addCase(deleteUser.rejected, (state, { payload }) => {
            state.error = payload;
        });
    },
});

export const { setPreviousPage, setNextPage, clear } = slice.actions;

export const selectUser =
    (userId: number | undefined) =>
    (state: RootState): IUser | undefined =>
        userId ? state.user.usersById[userId] : undefined;
export const selectCreatedUser = (state: RootState) => state.user.createdUser;
export const selectUsers = (state: RootState) => Object.values(state.user.users);
export const selectNextPage = (state: RootState) => state.user.nextPage;
export const selectPreviousPage = (state: RootState) => state.user.previousPage;
export const selectLoading = (state: RootState) => state.user.loading;
export const selectIsUpdatingUser = (state: RootState) => state.user.isUpdatingUser;
export const selectIsUpdateUserSuccess = (state: RootState) => state.user.isUpdateUserSuccess;
export const selectIsDeleteUserSuccess = (state: RootState) => state.user.isDeleteUserSuccess;
export const selectError = (state: RootState) => state.user.error;
export const selectImportLoading = (state: RootState) => state.user.importLoading;
export const selectImportSuccess = (state: RootState) => state.user.importSuccess;
export const selectImportResult = (state: RootState) => state.user.importResult;
export const selectImportError = (state: RootState) => state.user.importError;

export default slice.reducer;
