import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const ParentTable = styled.div`
    background-color: ${colours.white};
    width: 95%;
    border-radius: 6px;
    box-shadow: 0px 1px 9px ${colours.dropShadow};
    padding: 10px;

    @media ${sizes.M} {
        height: unset;
        padding: 45px;
    }

    @media ${sizes.G} {
        height: 90%;
    }
`;

export const IntroTextBox = styled.div`
    display: grid;
    grid-gap: 10px;
    place-items: center;
    margin-bottom: 25px;
`;

export const IntroTextTitle = styled.div`
    font-family: NunitoBold;
    font-size: 28px;
    color: ${colours.purple};
`;

export const IntroTextBody = styled.div`
    font-family: NunitoRegular;
    font-size: 20px;
    color: ${colours.black};
`;

export const Title = styled.div`
    font-family: NunitoBold;
    font-size: 20px;
    color: ${colours.purple};
`;

export const PersonContainer = styled.div`
    border: 1px solid ${colours.mediumGrey};
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 20px 0;
    font-family: PoppinsRegular;

    @media ${sizes.M} {
        flex-direction: row;
    }
`;

export const Name = styled.div`
    flex: 1;
`;

export const ActionLink = styled.button<{ inactive?: boolean; transparent?: boolean }>`
    border: none;
    font-family: NunitoBold;
    font-size: 18px;
    color: ${colours.purple};
    opacity: ${(props) => (props.transparent ? 0.4 : 1)};
    padding: 0;
    min-width: 65px;
    text-align: right;
    cursor: pointer;

    &:not(:last-of-type) {
        margin-right: 25px;
    }

    ${(props) =>
        props.inactive &&
        `
        cursor: default;
        color: ${colours.darkGrey};
        `}
`;
