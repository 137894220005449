const LINKS = {
    ENCOREWEBSITE_EN: 'https://www.encorestudy.ca/',
    ENCOREWEBSITE_FR: 'https://www.etudencore.ca/',
    BLOODVIDEOSAMPLELINE_EN: 'https://www.youtube.com/watch?v=n-_cVRoBXhQ',
    BLOODVIDEOSAMPLELINE_FR: 'https://www.youtube.com/watch?v=dsnhzBULfoE',
    INFOEMAIL_EN: 'info@encorestudy.ca',
    INFOEMAIL_FR: 'info@etudencore.ca',
};

export default LINKS;
