import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal, ModalContainer, Cancel, Message, ButtonsContainer } from '../../styles/modal';
import { LogoutButton } from './styles';
import { logoutUser } from '../../slices/authSlice';

type onCloseButtonFunction = (close: boolean) => void;

export interface IProps {
    onCloseButton: onCloseButtonFunction;
}

export const TeenagerModal: React.FunctionComponent<IProps> = ({ onCloseButton }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <ModalContainer>
            <Modal>
                <Message center>{t('survey.teenagerModal.consentMessage')}</Message>
                <ButtonsContainer center>
                    <LogoutButton
                        onClick={() => {
                            dispatch(logoutUser());
                        }}
                    >
                        {t('header.saveAndContinueLater')}
                    </LogoutButton>
                    <Cancel onClick={() => onCloseButton(false)}>OK</Cancel>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
