import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable */
import { format as formatDate, formatISO9075 } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
/* eslint-enable */
import { Redirect, useHistory } from 'react-router-dom';

// Components
import { ChildConsentModal } from '../../components/ChildConsentModal';
import { DashboardHeader } from '../../components/DashboardHeader';
import { InformationModal } from '../../components/InformationModal';
import Loader from '../../components/Loader';

// Styles
import { DashboardPageContainer, DashboardType, Link } from '../../styles/common';
import {
    ActionLink,
    IntroTextBody,
    IntroTextBox,
    IntroTextTitle,
    Name,
    ParentTable,
    PersonContainer,
    Title,
} from './styles';

// Slices
import {
    IUser,
    getUser,
    selectUser,
    updateUser,
    selectIsUpdateUserSuccess,
    selectError,
} from '../../slices/userSlice';
import { selectAuthenticatedUser } from '../../slices/authSlice';
import { clear, setChild } from '../../slices/childSlice';

// Consts
import { ROUTES } from '../../constants/routes';
import { AdminPasswordModal } from '../../components/AdminPasswordModal';
import { AdminProfileModal } from '../../components/AdminProfileModal';

import { calculateAge } from '../../utils/date';
import LANGUAGES from '../../constants/languages';

const ParentDashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language
    const dispatch = useDispatch();
    const history = useHistory();

    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const user = useSelector(selectUser(authenticatedUser?.id));

    const hasAllChildrenWithSurvey = user?.hasAllChildrenWithSurvey;
    const hasChildrenParticipatingToStudy = user?.hasChildrenParticipatingToStudy;

    const locale = language === LANGUAGES.EN ? enCA : frCA;

    useEffect(() => {
        if (authenticatedUser?.id) {
            dispatch(getUser(authenticatedUser.id));
        }
    }, [dispatch, authenticatedUser]);

    useEffect(() => {
        const shouldDisplayModalForNonParticipatingHousehold =
            hasAllChildrenWithSurvey && !hasChildrenParticipatingToStudy;
        setDisplayInfoModal(shouldDisplayModalForNonParticipatingHousehold ?? false);
    }, [hasAllChildrenWithSurvey, hasChildrenParticipatingToStudy]);

    const household = useSelector(selectUser(user?.id));

    const [childConsentModalOpen, setChildConsentModalOpen] = useState<boolean>(false);
    const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
    const [selectedChild, setSelectedChild] = useState<IUser | undefined>(undefined);
    const [updatingUser, setUpdatingUser] = useState<boolean>();
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
    const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);

    const isUpdateUserSuccess = useSelector(selectIsUpdateUserSuccess);
    const isUpdateUserError = useSelector(selectError);

    useEffect(() => {
        if (updatingUser && isUpdateUserSuccess) {
            setChildConsentModalOpen(false);
            setUpdatingUser(false);
        }
    }, [isUpdateUserError, isUpdateUserSuccess, updatingUser]);

    const onCloseModal = () => {
        setSelectedChild(undefined);
        setChildConsentModalOpen(false);
    };

    const onSaveModal = (
        participatingToStudy: IUser['participatingToStudy'],
        participatingToBloodSample: IUser['participatingToBloodSample'],
    ) => {
        if (user && selectedChild) {
            setUpdatingUser(true);
            const userChildren = user.children ?? [];
            if (!participatingToStudy) {
                dispatch(
                    updateUser({
                        id: user.id,
                        profile: {
                            id: user.profile.id,
                        },
                        children: [
                            ...userChildren,
                            {
                                id: selectedChild.id,
                                participatingToStudy,
                                participatingToBloodSample,
                                profile: {
                                    id: selectedChild.profile.id,
                                },
                                survey: {
                                    ...selectedChild.survey,
                                    surveyId: 1, // We just have 1 survey at the moment
                                    userId: selectedChild.id,
                                    currentQuestionId: 1,
                                    completedAt: formatISO9075(new Date()),
                                },
                            },
                        ],
                    }),
                );
            }
            if (participatingToStudy) {
                dispatch(
                    updateUser({
                        id: user.id,
                        profile: {
                            id: user.profile.id,
                        },
                        children: [
                            ...userChildren,
                            {
                                id: selectedChild.id,
                                participatingToStudy,
                                participatingToBloodSample,
                                profile: {
                                    id: selectedChild.profile.id,
                                },
                                survey: {
                                    ...selectedChild.survey,
                                    surveyId: 1, // We just have 1 survey at the moment
                                    userId: selectedChild.id,
                                    currentQuestionId: null,
                                    completedAt: null,
                                },
                            },
                        ],
                    }),
                );
            }
        }
    };

    const startChildSurvey = (child: IUser) => {
        dispatch(
            setChild({
                id: child.id,
                householdId: child?.householdId,
                firstWaveDate: child.firstWaveDate,
                firstName: child.profile.firstName,
                schoolCpe: child.profile.schoolCpe,
            }),
        );
        history.push(ROUTES.SURVEY);
    };

    const openChildModal = (child: IUser) => {
        setSelectedChild(child);
        setChildConsentModalOpen(true);
    };

    const startHouseholdSurvey = () => {
        dispatch(clear());
        history.push(ROUTES.SURVEY);
    };

    if (!user || !household) {
        return <Loader />;
    }

    if (user?.hasAllChildrenCompletedSurvey && user.survey?.completedAt !== null) {
        return <Redirect to={ROUTES.THANK_YOU} />;
    }

    const sortChildren = (child1: IUser, child2: IUser) => {
        const nameA = child1.profile.firstName;
        const nameb = child2.profile.firstName;
        // eslint-disable-next-line no-nested-ternary
        return nameA < nameb ? 1 : nameA > nameb ? -1 : 0;
    };

    return (
        <DashboardPageContainer type={DashboardType.household}>
            <Helmet>
                <title>{t('parentDashboard.helmet')}</title>
            </Helmet>
            {passwordModalOpen && <AdminPasswordModal onCloseButton={setPasswordModalOpen} />}
            {profileModalOpen && (
                <AdminProfileModal viewMode={true} onCloseButton={setProfileModalOpen} />
            )}

            {childConsentModalOpen && selectedChild && (
                <ChildConsentModal
                    child={selectedChild}
                    error={Boolean(isUpdateUserError)}
                    onCloseButton={onCloseModal}
                    onSaveButton={onSaveModal}
                />
            )}
            {displayInfoModal && (
                <InformationModal
                    bodyText={
                        <Trans i18nKey="parentDashboard.messageNotParticipatingHousehold">
                            Thank you for participating in the first round of collection for the
                            EnCORE study! If you change your mind and would like to participate in
                            this second collection, you can do so by contacting us at
                            <Link href="mailto:info@encorestudy.ca" target="_blank">
                                info@etudencore.ca
                            </Link>
                            or by phone/SMS at 1-866-362-6730.
                        </Trans>
                    }
                    onCloseButton={() => setDisplayInfoModal(false)}
                />
            )}
            <DashboardHeader
                profileModal={setProfileModalOpen}
                passwordModal={setPasswordModalOpen}
                userName={user.profile.firstName}
            />
            <ParentTable>
                <IntroTextBox>
                    <IntroTextTitle>{t('parentDashboard.introText1')}</IntroTextTitle>
                    <IntroTextBody>{t('parentDashboard.introText2')}</IntroTextBody>
                    <IntroTextBody>{t('parentDashboard.introText3')}</IntroTextBody>
                    <IntroTextBody>{t('parentDashboard.introText4')}</IntroTextBody>
                </IntroTextBox>
                <Title>{t('parentDashboard.household')}</Title>
                <PersonContainer>
                    <Name>
                        {household.profile.firstName} {household.profile.lastName}
                    </Name>
                    {household.survey?.completedAt ? (
                        <ActionLink inactive>{t('parentDashboard.completed')}</ActionLink>
                    ) : (
                        <ActionLink onClick={startHouseholdSurvey}>
                            {t('parentDashboard.start')}
                        </ActionLink>
                    )}
                </PersonContainer>

                {household?.children
                    ?.slice()
                    .sort(sortChildren)
                    ?.map((child, index) => {
                        const age = child.profile.birthday
                            ? calculateAge(child.profile.birthday)
                            : null;
                        return (
                            <div key={child.id}>
                                <Title>
                                    {t('parentDashboard.child')} {index + 1}{' '}
                                    {t('parentDashboard.childQuestionnaireTime')}
                                </Title>
                                <PersonContainer>
                                    <Name>
                                        {`${child.profile.firstName} - ${age} `}
                                        {t('parentDashboard.years')} - {t('parentDashboard.wave1')}{' '}
                                        {child.firstWaveDate &&
                                            formatDate(new Date(child.firstWaveDate), 'MMMM yyyy', {
                                                locale,
                                            })}
                                    </Name>
                                    {!child.survey?.completedAt && (
                                        <ActionLink
                                            transparent={child.participatingToStudy}
                                            onClick={() => openChildModal(child)}
                                        >
                                            {child.participatingToStudy
                                                ? t('parentDashboard.confirm')
                                                : t('parentDashboard.signUp')}
                                        </ActionLink>
                                    )}
                                    {child.participatingToStudy && !child.survey?.completedAt && (
                                        <ActionLink onClick={() => startChildSurvey(child)}>
                                            {t('parentDashboard.start')}
                                        </ActionLink>
                                    )}
                                    {child.participatingToStudy && child.survey?.completedAt && (
                                        <>
                                            <ActionLink inactive>
                                                {t('parentDashboard.confirm')}
                                            </ActionLink>
                                            <ActionLink inactive>
                                                {t('parentDashboard.completed')}
                                            </ActionLink>
                                        </>
                                    )}
                                    {!child.participatingToStudy && child.survey?.completedAt && (
                                        <>
                                            <ActionLink inactive>
                                                {t('parentDashboard.nonParticipant')}
                                            </ActionLink>
                                        </>
                                    )}
                                </PersonContainer>
                            </div>
                        );
                    })}
            </ParentTable>
        </DashboardPageContainer>
    );
};

export default ParentDashboard;
