/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';

import { Helmet } from 'react-helmet';
import _ from 'lodash';

// Components
import { Footer } from '../../components/Footer';
import { HeaderQuestion } from '../../components/HeaderQuestion';
import { CheckboxQuestion } from '../../components/CheckboxQuestion';
import { RadioQuestion } from '../../components/RadioQuestion';
import { TextfieldQuestion } from '../../components/TextfieldQuestion';
import { DateQuestion } from '../../components/DateQuestion';
import { DropdownQuestion } from '../../components/DropdownQuestion';
import { NumberQuestion } from '../../components/NumberQuestion';
import { SelectboxQuestion } from '../../components/SelectboxQuestion';
import { MultiRadioQuestion } from '../../components/MultiRadioQuestion';
import { GroupedQuestion } from '../../components/GroupedQuestion';
import { TeenagerModal } from '../../components/TeenagerModal';

// Styles
import {
    AnswersContainer,
    QuestionTitle,
    SurveyCenterContainer,
    TitleContainer,
    PageContainer,
} from './styles';
import Loader from '../../components/Loader';

// Slices and types
import {
    getCurrentQuestion,
    QuestionType,
    SectionType,
    saveQuestion,
    saveObject,
    selectQuestion,
} from '../../slices/surveySlice';
import { selectChild } from '../../slices/childSlice';
import { selectAuthenticatedUser } from '../../slices/authSlice';
import { selectUser } from '../../slices/userSlice';

// Utils
import { MultitypeQuestion } from '../../components/MultitypeQuestion';
import { ROUTES } from '../../constants/routes';
import LANGUAGES from '../../constants/languages';
import { ConfirmAddress } from '../../components/ConfirmAddress';

enum Question {
    checkbox = 'checkbox',
    radio = 'radio',
    text = 'text',
    date = 'date',
    dropdown = 'dropdown',
    number = 'number',
    select = 'select',
    multiradio = 'multiradio',
    multitype = 'multitype',
    grouped = 'groupedtype',
}
interface IProps {
    id: number;
}

const Survey: React.FunctionComponent<IProps> = (props: IProps) => {
    const [currentSection, setCurrentSection] = useState<number>(1); // This value holds the current section
    const [displayTeenagerModal, setDisplayTeenagerModal] = useState<boolean>(false);

    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/login');
        }
    });

    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const question = useSelector(selectQuestion);
    const user = useSelector(selectUser(authenticatedUser?.id));
    const child = useSelector(selectChild);

    const name = child?.firstName;

    const getDate = () => {
        const locale = language === LANGUAGES.EN ? enCA : frCA;
        const format = language === LANGUAGES.EN ? 'MMMM do yyyy' : 'dd MMMM yyyy';
        if (!child?.firstWaveDate) {
            return null;
        }
        return formatDate(new Date(String(child.firstWaveDate)), format, { locale });
    };

    const schoolCpe = child?.schoolCpe;

    const isFirstQuestionOfSection = question?.isFirstQuestionOfSection ?? false;
    const sectionId = question?.sectionId;

    useEffect(() => {
        setDisplayTeenagerModal(isFirstQuestionOfSection && sectionId === 3);
    }, [isFirstQuestionOfSection, sectionId]);

    useEffect(() => {
        dispatch(getCurrentQuestion({ userId: child ? child.id : user?.id }));
        dispatch(saveObject([]));
    }, [child, dispatch, user]);

    if ((!child && !user) || (_.isEmpty(question) && typeof question !== 'undefined')) {
        return <Redirect to={ROUTES.PARENT_DASHBOARD} />;
    }

    if (typeof question === 'undefined') {
        return <Loader />;
    }

    const date = getDate();

    const order = question.order;
    const surveyLength = question.totalQuestions;
    const sectionToDisplay: SectionType = question.section;
    const sectionLength = question.totalSectionQuestions;

    const canChangeCurrentQuestion = (question: QuestionType) => {
        const { question_option } = question;
        const hasSelectedOption =
            question_option.findIndex((option) => option.selected.length > 0) !== -1;

        const hasSelectedChild =
            question_option.findIndex(
                (father) =>
                    father.question_child.findIndex((child) => child.selected.length > 0) !== -1,
            ) !== -1;
        const hasAnswer = question.answer && question.answer.length > 0;

        return hasSelectedChild || hasSelectedOption || hasAnswer;
    };

    const renderElement = (element: QuestionType) => {
        if (element.id === 2070) {
            return (
                <ConfirmAddress
                    question={element.id}
                    options={element.question_option}
                    profile={authenticatedUser?.profile}
                />
            );
        }
        switch (element.question_type.type) {
            case Question.checkbox:
                return (
                    <CheckboxQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.radio:
                return (
                    <RadioQuestion
                        question={element.id}
                        key={element.id}
                        text={element.text}
                        options={element.question_option}
                    />
                );
            case Question.text:
                return (
                    <TextfieldQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        mask={element.text === 'postalCode' ? 'a9a-9a9' : ''}
                        value={element.answer}
                    />
                );
            case Question.date:
                return (
                    <DateQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        value={element.answer}
                    />
                );
            case Question.dropdown:
                return (
                    <DropdownQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.number:
                return (
                    <NumberQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        value={element.answer.length > 0 ? Number(element.answer[0].answer) : null}
                        min={element.min}
                        max={element.max}
                        subtype={element.subtype}
                    />
                );
            case Question.select:
                return <SelectboxQuestion question={{ ...element }} />;
            case Question.multiradio:
                return (
                    <MultiRadioQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.grouped:
                return (
                    <GroupedQuestion
                        question={element.id}
                        options={element.question_option}
                        value={element.answer}
                        key={element.id}
                    />
                );
            case Question.multitype:
                return (
                    <MultitypeQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                        conditional={element.conditional_question}
                    />
                );
            default:
                return (
                    <div key={element.id}>
                        Question {element.order}: Component {element.question_type.type} to be
                        deployed soon
                    </div>
                );
        }
    };

    const onNext = () => {
        dispatch(saveQuestion({ increment: true, userId: child ? child.id : user?.id }));
        if (question.id === sectionLength && currentSection !== surveyLength) {
            setCurrentSection(currentSection + 1);
        }
    };

    const onPrevious = () => {
        dispatch(saveQuestion({ increment: false, userId: child ? child.id : user?.id }));
        if (question.id === 1 && currentSection !== 1) {
            setCurrentSection(currentSection - 1);
        }
    };
    return (
        <PageContainer>
            <Helmet>
                <title>{t('survey.questionnaire')}</title>
            </Helmet>
            <HeaderQuestion
                question={order}
                section={sectionToDisplay.id}
                sectionName={sectionToDisplay.name}
                sectionLength={sectionLength}
                onPrevious={onPrevious}
            />
            {displayTeenagerModal && (
                <TeenagerModal onCloseButton={() => setDisplayTeenagerModal(false)} />
            )}
            <SurveyCenterContainer>
                <TitleContainer>
                    <QuestionTitle>
                        {t(`survey.${question.text}`, { name, date, schoolCpe })}
                    </QuestionTitle>
                </TitleContainer>
                <AnswersContainer>{renderElement(question)}</AnswersContainer>
            </SurveyCenterContainer>
            <Footer
                question={question}
                section={currentSection}
                onNext={onNext}
                onPrevious={onPrevious}
                hasAnswer={canChangeCurrentQuestion(question)}
                isLastQuestion={false}
            />
        </PageContainer>
    );
};

export default Survey;
