import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { HelpInfo } from '../../components/HelpInfo';

import HomeLayout from '../../layouts/home';

import { Title, Subtitle, Subtitle2, Login, LinkGroup } from './styles';
import { ROUTES } from '../../constants/routes';

const Home: React.FC = () => {
    const { t } = useTranslation();

    // If a token already exists, it might be an artifact from a previous version of the website
    // and therefore, should be reset
    localStorage.removeItem('token');

    return (
        <>
            <Helmet>
                <title>{t('homepage.home')}</title>
            </Helmet>
            <HomeLayout leftSide>
                <Title>{t('homepage.title')}</Title>
                <Subtitle>{t('homepage.subtitle1')}</Subtitle>
                <Subtitle2>{t('homepage.subtitle2')}</Subtitle2>
                <LinkGroup>
                    <NavLink to={ROUTES.WELCOME_BACK} exact>
                        <Button label={t('homepage.button')} />
                    </NavLink>
                    <Login to={ROUTES.LOGIN} exact>
                        {t('homepage.login')}
                    </Login>
                </LinkGroup>
                <HelpInfo />
            </HomeLayout>
        </>
    );
};

export default Home;
