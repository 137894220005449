import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    LoginField,
    Error,
    Label,
    Fields,
    WelcomeTextContainer,
    ResetLink,
    Container,
    EmailLink,
} from './styles';

import HomeLayout from '../../layouts/home';

import { Button } from '../../components/Button';

import { RootState } from '../../store';
import { loginUser, selectAuthenticatedUser } from '../../slices/authSlice';

import { ROUTES } from '../../constants/routes';
import ROLES from '../../constants/roles';

import { isUserAdminOrCoordinator } from '../../utils/roles-check';
import LANGUAGES from '../../constants/languages';
import LINKS from '../../constants/links';

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const history = useHistory();
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const getEmail = () => {
        if (language === LANGUAGES.EN) return `mailto:${LINKS.INFOEMAIL_EN}`;
        return `mailto:${LINKS.INFOEMAIL_FR}`;
    };

    const authError = useSelector((state: RootState) => state.auth.errors);
    const user = useSelector(selectAuthenticatedUser);
    const userRole = user ? user.role : undefined;

    // If user is authenticated, redirect to admin home. If user is not admin or coordinator, redirect to parent dashboard page.
    useEffect(() => {
        if (user) {
            if (user.role === ROLES.HOUSEHOLD) {
                history.push(ROUTES.PARENT_DASHBOARD);
            } else if (isUserAdminOrCoordinator(user)) {
                history.push(ROUTES.ADMIN);
            }
        }
    }, [history, user, userRole]);

    const authHandler = () => {
        // In case the user wrongly leaves whitespace in the fields
        setEmail(email.trim());
        setPassword(password.trim());
        dispatch(loginUser({ email, password }));
    };

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout leftSide footerText={t('login.footer')}>
                <Container>
                    <WelcomeTextContainer>
                        <Trans i18nKey="login.welcomeText">
                            Already participating in the EnCORE study? You should have already
                            received an email invitation for the second round of collection for the
                            study. It is possible that it is in your junk mail. If not, please
                            contact us at
                            <EmailLink href={getEmail()}>info@encorestudy.ca</EmailLink> or by
                            phone/SMS at 1-866-362-6730.
                        </Trans>
                    </WelcomeTextContainer>

                    <Fields>
                        <Label htmlFor="login">{t('login.username')}</Label>
                        <LoginField
                            type="text"
                            name="login"
                            id="login"
                            value={`${email}`.toLowerCase()}
                            onChange={(event) => setEmail(`${event.target.value}`.toLowerCase())}
                            autoCapitalize="none"
                        />
                    </Fields>
                    <Fields>
                        <Label htmlFor="password">{t('login.password')}</Label>
                        <LoginField
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            autoCapitalize="none"
                        />
                        {authError && <Error>{t('login.authError')}</Error>}
                        <WelcomeTextContainer>
                            <Trans i18nKey="login.lostPassword">
                                If you have lost your password, you can reset it by clicking
                                <ResetLink to="/reset-password">here</ResetLink>.
                            </Trans>
                        </WelcomeTextContainer>
                    </Fields>
                    <Button label={t('login.login')} onClick={authHandler} justifySelf="center" />
                </Container>
            </HomeLayout>
        </>
    );
};

export default Login;
