import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format as formatDate } from 'date-fns';

import { RadioOption } from '../RadioOption';
import Loader from '../Loader';

import SuccessIcon from '../../assets/icons/success.svg';
import CloseIcon from '../../assets/icons/close_icon.svg';
import { FieldsGroup, FieldsGroupContainer, RadioGroup } from './styles';
import { FieldError } from '../../styles/common';
import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';

import { selectAuthenticatedUser } from '../../slices/authSlice';
import {
    IUser,
    IUserProfile,
    getUser,
    selectUser,
    selectIsUpdatingUser,
    selectIsUpdateUserSuccess,
    updateUser,
    clearUser,
} from '../../slices/userSlice';

import {
    validateEmail,
    validateFirstName,
    validateLastName,
    validateBirthday,
    validateCity,
    validateFirstAddressLine,
    validatePostalCode,
    validateProvince,
    validateSecondAddressLine,
    validatePhone,
} from '../../utils/validation';
import { isUserAdmin, isUserHousehold } from '../../utils/roles-check';
import { correctTimeZone, calculateAge } from '../../utils/date';

import ROLES from '../../constants/roles';

export interface IProps {
    participantId: IUser['id'];
    childIndex?: number;
    onCloseButton: () => void;
}

export const AdminParticipantInfoModal: React.FunctionComponent<IProps> = ({
    participantId,
    childIndex,
    onCloseButton,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser(participantId));
    }, [dispatch, participantId]);

    const [editMode, setEditMode] = useState<boolean>();
    const [participant, setParticipant] = useState<IUser>();
    const [isHousehold, setIsHousehold] = useState<boolean>(false);
    const [isChild, setIsChild] = useState<boolean>(false);
    const [invalidEmail, setEmailInvalid] = useState<boolean>(false);
    const [invalidFirstName, setFirstNameInvalid] = useState<boolean>(false);
    const [invalidLastName, setLastNameInvalid] = useState<boolean>(false);
    const [invalidBirthday, setBirthdayInvalid] = useState<boolean>(false);
    const [invalidPostalCode, setPostalCodeInvalid] = useState<boolean>(false);
    const [invalidFirstAddressLine, setFirstAddressLineInvalid] = useState<boolean>(false);
    const [invalidSecondAddressLine, setSecondAddressLineInvalid] = useState<boolean>(false);
    const [invalidCity, setCityInvalid] = useState<boolean>(false);
    const [invalidProvince, setProvinceInvalid] = useState<boolean>(false);
    const [invalidPhone, setPhoneInvalid] = useState<boolean>(false);

    const sourceParticipant = useSelector(selectUser(participantId));
    const updateInProgress = useSelector(selectIsUpdatingUser);
    const success = useSelector(selectIsUpdateUserSuccess);

    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const [authenticatedUserCanEdit, setAuthenticatedUserCanEdit] = useState(false);

    useEffect(() => {
        if (participant) {
            setIsHousehold(participant.role === ROLES.HOUSEHOLD);
            setIsChild(participant.role === ROLES.CHILD);
        }
    }, [participant]);

    useEffect(() => {
        if (authenticatedUser && isUserAdmin(authenticatedUser)) {
            setAuthenticatedUserCanEdit(true);
        }
    }, [authenticatedUser]);

    const isEmailValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateEmail(participant.email);
        setEmailInvalid(!isValid);
        return isValid;
    };

    const isFirstNameValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateFirstName(participant.profile.firstName);
        setFirstNameInvalid(!isValid);
        return isValid;
    };

    const isLastNameValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateLastName(participant.profile.lastName);
        setLastNameInvalid(!isValid);
        return isValid;
    };

    const isBirthdayValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateBirthday(participant.profile.birthday);
        setBirthdayInvalid(!isValid);
        return isValid;
    };

    const isPostalCodeValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validatePostalCode(participant.profile.postalCode);
        setPostalCodeInvalid(!isValid);
        return isValid;
    };

    const isFirstAddressLineValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateFirstAddressLine(participant.profile.firstAddressLine);
        setFirstAddressLineInvalid(!isValid);
        return isValid;
    };

    const isSecondAddressLineValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateSecondAddressLine(participant.profile.secondAddressLine);
        setSecondAddressLineInvalid(!isValid);
        return isValid;
    };

    const isCityValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateCity(participant.profile.city);
        setCityInvalid(!isValid);
        return isValid;
    };

    const isProvinceValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateProvince(participant.profile.province);
        setProvinceInvalid(!isValid);
        return isValid;
    };

    const isPhoneValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validatePhone(participant.profile.phone);
        setPhoneInvalid(!isValid);
        return isValid;
    };

    const onEditMode = (newEditMode: boolean) => {
        if (!sourceParticipant) {
            return;
        }
        if (newEditMode) {
            setEditMode(true);
            setParticipantAsCopy();
        } else {
            setEditMode(false);
            setParticipant(sourceParticipant);
        }
    };

    // we'll be editing so we need a copy to play with
    const setParticipantAsCopy = () => {
        if (!sourceParticipant) {
            return;
        }
        setParticipant({
            ...sourceParticipant,
            profile: {
                ...sourceParticipant.profile,
            },
        });
    };

    const onChangeValue = () => {
        dispatch(clearUser());
    };

    const modifyLocalParticipant = (candidateParticipant: IUser) => {
        onChangeValue();
        setParticipant(candidateParticipant);
    };

    const updateLanguage = (language: IUserProfile['language']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                language,
            },
        });
    };

    const updateEmail = (email: IUser['email']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            email,
            profile: {
                ...participant.profile,
            },
        });
    };

    const updateFirstName = (firstName: IUserProfile['firstName']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                firstName,
            },
        });
    };

    const updateLastName = (lastName: IUserProfile['lastName']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                lastName,
            },
        });
    };

    const updateBirthday = (birthday: IUserProfile['birthday']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                birthday,
            },
        });
    };

    const updatePostalCode = (postalCode: IUserProfile['postalCode']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                postalCode,
            },
        });
    };

    const updateFirstAddressLine = (firstAddressLine: IUserProfile['firstAddressLine']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                firstAddressLine,
            },
        });
    };

    const updateSecondAddressLine = (secondAddressLine: IUserProfile['secondAddressLine']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                secondAddressLine,
            },
        });
    };

    const updateCity = (city: IUserProfile['city']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                city,
            },
        });
    };

    const updateProvince = (province: IUserProfile['province']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                province,
            },
        });
    };

    const updatePhone = (phone: IUserProfile['phone']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                phone,
            },
        });
    };

    const updateTestKitSent = (testKitSent: IUser['testKitSent']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            testKitSent,
        });
    };

    const updateSampleReceivedAtLab = (sampleReceivedAtLab: IUser['sampleReceivedAtLab']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            sampleReceivedAtLab,
        });
    };

    const updateLabStatusResult = (labStatusResult: IUser['labStatusResult']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            labStatusResult,
        });
    };

    const updateParticipatingToStudy = (participatingToStudy: IUser['participatingToStudy']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            participatingToStudy,
            profile: {
                ...participant.profile,
            },
        });
    };

    const updateParticipatingToBloodSample = (
        participatingToBloodSample: IUser['participatingToBloodSample'],
    ) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            participatingToBloodSample,
            profile: {
                ...participant.profile,
            },
        });
    };

    const updateDroppedOut = (droppedOut: IUser['droppedOut']) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            droppedOut,
        });
    };

    const isFormValid = () => {
        if (isHousehold) {
            return (
                isEmailValid() &&
                isFirstNameValid() &&
                isLastNameValid() &&
                isPostalCodeValid() &&
                isFirstAddressLineValid() &&
                isSecondAddressLineValid() &&
                isCityValid() &&
                isProvinceValid() &&
                isPhoneValid()
            );
        } else {
            return isFirstNameValid() && isBirthdayValid();
        }
    };

    const onUpdateForm = () => {
        if (updateInProgress || !participant || !isFormValid()) {
            return;
        }

        dispatch(updateUser(participant));
    };

    const onClose = () => {
        dispatch(clearUser());
        onCloseButton();
    };

    // means we just opened the modal, we need to set the edit mode
    if (typeof editMode === 'undefined') {
        onEditMode(false);
    }

    const getCloseButton = () => (
        <CloseButton onClick={onClose}>
            <img src={CloseIcon} alt="close" />
        </CloseButton>
    );

    const getHouseholdId = (): JSX.Element => (
        <FieldContainer inline>
            <Label>{t('admin.participantInfoModal.householdId')} </Label>
            <Field type="text" value={participant?.householdId ?? ''} disabled={true} />
        </FieldContainer>
    );

    const getChildId = (): JSX.Element => (
        <FieldContainer inline>
            <Label>{t('admin.participantInfoModal.childId')} </Label>
            <Field type="text" value={participant?.childId ?? ''} disabled={true} />
        </FieldContainer>
    );

    const getAge = (): JSX.Element => {
        const age = participant?.profile.birthday ? calculateAge(participant.profile.birthday) : '';
        return (
            <FieldContainer inline>
                <Label>{t('admin.participantInfoModal.age')} </Label>
                <Field type="text" value={age} disabled={true} />
            </FieldContainer>
        );
    };

    const getEnrollmentDateField = (): JSX.Element => {
        const correctedOnboardedAt = correctTimeZone(String(participant?.onboardedAt));
        const enrollmentDate = participant?.onboardedAt
            ? formatDate(new Date(correctedOnboardedAt), 'yyyy-MM-dd')
            : '';

        return (
            <FieldContainer inline>
                <Label>{t('admin.participantInfoModal.enrollmentDate')} </Label>
                <Field type="text" value={enrollmentDate} disabled={true} />
            </FieldContainer>
        );
    };

    const getCompletionDateField = (): JSX.Element => {
        const correctedCompletedAt = correctTimeZone(String(participant?.survey?.completedAt));
        const completionDate =
            participant?.survey?.completedAt && participant.survey.completedAt.length
                ? formatDate(new Date(correctedCompletedAt), 'yyyy-MM-dd')
                : 'N/A';

        return (
            <FieldContainer inline>
                <Label>{t('admin.participantInfoModal.completionDate')}</Label>
                <Field type="text" value={completionDate} disabled={true} />
            </FieldContainer>
        );
    };

    const getStatusField = (): JSX.Element => {
        const droppedOutOptions = [
            {
                label: t('admin.participantInfoModal.inactive'),
                value: true,
            },
            { label: t('admin.participantInfoModal.active'), value: false },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.dropoutStatus')}</Label>
                {!editMode ? (
                    <Field
                        value={
                            (
                                droppedOutOptions.find(
                                    (option) => option.value === participant?.droppedOut,
                                ) ?? droppedOutOptions[0]
                            ).label
                        }
                        disabled
                    />
                ) : (
                    <RadioGroup>
                        {droppedOutOptions.map(({ label, value }) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={stringValue}
                                    handleChange={() => updateDroppedOut(value)}
                                    name={stringValue}
                                    id={stringValue}
                                    value={stringValue}
                                    text={label}
                                    checked={value === participant?.droppedOut}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
            </FieldContainer>
        );
    };

    const getLanguageField = (): JSX.Element => {
        const languageOptions = [
            [t('admin.participantInfoModal.english'), 'en'],
            [t('admin.participantInfoModal.french'), 'fr'],
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.language')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {languageOptions.map(([text, value]) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateLanguage(value)}
                                    name={String(value)}
                                    id={value}
                                    value={value}
                                    text={String(text)}
                                    checked={value === participant?.profile.language}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (languageOptions.find(
                                (option) => option[1] === participant?.profile?.language,
                            ) ?? languageOptions[1])[0]
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getEmailField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.email')}</Label>
            <Field
                type="text"
                value={`${participant?.email}`.toLowerCase()}
                disabled={!editMode}
                onChange={(event) => {
                    updateEmail(`${event.target.value}`.toLowerCase());
                }}
                onBlur={isEmailValid}
            />
            {invalidEmail && (
                <FieldError>{t('admin.participantInfoModal.invalidEmail')}</FieldError>
            )}
        </FieldContainer>
    );

    const getFirstNameField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.firstName')}</Label>
            <Field
                type="text"
                value={participant?.profile.firstName}
                disabled={!editMode}
                onChange={(event) => {
                    updateFirstName(event.target.value);
                }}
                onBlur={isFirstNameValid}
            />
            {invalidFirstName && (
                <FieldError>{t('admin.participantInfoModal.invalidFirstName')}</FieldError>
            )}
        </FieldContainer>
    );

    const getLastNameField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.lastName')}</Label>
            <Field
                type="text"
                value={participant?.profile.lastName}
                disabled={!editMode}
                onChange={(event) => {
                    updateLastName(event.target.value);
                }}
                onBlur={isLastNameValid}
            />
            {invalidLastName && (
                <FieldError>{t('admin.participantInfoModal.invalidLastName')}</FieldError>
            )}
        </FieldContainer>
    );

    const getBirthdayField = (): JSX.Element => {
        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.birthday')}</Label>
                <Field
                    type="text"
                    value={participant?.profile.birthday}
                    disabled={!editMode}
                    onChange={(event) => {
                        updateBirthday(event.target.value);
                    }}
                    onBlur={isBirthdayValid}
                />
                {invalidBirthday && (
                    <FieldError>{t('admin.participantInfoModal.invalidBirthday')}</FieldError>
                )}
            </FieldContainer>
        );
    };

    const getFirstAddressLineField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.firstAddressLine')}</Label>
            <Field
                type="text"
                value={participant?.profile.firstAddressLine}
                disabled={!editMode}
                onChange={(event) => {
                    updateFirstAddressLine(event.target.value);
                }}
                onBlur={isFirstAddressLineValid}
            />
            {invalidFirstAddressLine && (
                <FieldError>{t('admin.participantInfoModal.invalidFirstAddressLine')}</FieldError>
            )}
        </FieldContainer>
    );

    const getSecondAddressLineField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.secondAddressLine')}</Label>
            <Field
                type="text"
                value={participant?.profile.secondAddressLine}
                disabled={!editMode}
                onChange={(event) => {
                    updateSecondAddressLine(event.target.value);
                }}
                onBlur={isSecondAddressLineValid}
            />
            {invalidSecondAddressLine && (
                <FieldError>{t('admin.participantInfoModal.invalidSecondAddressLine')}</FieldError>
            )}
        </FieldContainer>
    );

    const getPostalCodeField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.postalCode')}</Label>
            <Field
                type="text"
                value={participant?.profile.postalCode}
                disabled={!editMode}
                onChange={(event) => {
                    updatePostalCode(event.target.value);
                }}
                onBlur={isPostalCodeValid}
            ></Field>
            {invalidPostalCode && (
                <FieldError>{t('admin.participantInfoModal.invalidPostalCode')}</FieldError>
            )}
        </FieldContainer>
    );

    const getCityField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.city')}</Label>
            <Field
                type="text"
                value={participant?.profile.city}
                disabled={!editMode}
                onChange={(event) => {
                    updateCity(event.target.value);
                }}
                onBlur={isCityValid}
            />
            {invalidCity && <FieldError>{t('admin.participantInfoModal.invalidCity')}</FieldError>}
        </FieldContainer>
    );

    const getProvinceField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.province')}</Label>
            <Field
                type="text"
                value={participant?.profile.province}
                disabled={!editMode}
                onChange={(event) => {
                    updateProvince(event.target.value);
                }}
                onBlur={isProvinceValid}
            />
            {invalidProvince && (
                <FieldError>{t('admin.participantInfoModal.invalidProvince')}</FieldError>
            )}
        </FieldContainer>
    );

    const getPhoneField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.phone')}</Label>
            <Field
                type="text"
                value={participant?.profile.phone}
                disabled={!editMode}
                onChange={(event) => {
                    updatePhone(event.target.value);
                }}
                onBlur={isPhoneValid}
            />
            {invalidPhone && (
                <FieldError>{t('admin.participantInfoModal.invalidPhone')}</FieldError>
            )}
        </FieldContainer>
    );

    const getTestKitSentField = (): JSX.Element => {
        const testKitSentOptions = [
            { label: t('admin.participantInfoModal.yes'), value: true },
            {
                label: t('admin.participantInfoModal.no'),
                value: false,
            },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.testKitSent')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {testKitSentOptions.map(({ label, value }) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={String(value)}
                                    handleChange={() => updateTestKitSent(Boolean(value))}
                                    name={String(value)}
                                    id={stringValue}
                                    value={stringValue}
                                    text={label}
                                    checked={value === participant?.testKitSent}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (
                                testKitSentOptions.find(
                                    (option) => option.value === participant?.testKitSent,
                                ) ?? testKitSentOptions[1]
                            ).label
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getSampleReceivedAtLabField = (): JSX.Element => {
        const sampleReceivedAtLabOptions = [
            { label: t('admin.participantInfoModal.yes'), value: 'yes' },
            { label: t('admin.participantInfoModal.no'), value: 'no' },
            { label: t('admin.participantInfoModal.enRoute'), value: 'en route' },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.sampleReceivedAtLab')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {sampleReceivedAtLabOptions.map(({ label, value }) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateSampleReceivedAtLab(value)}
                                    name={value}
                                    id={value}
                                    value={value}
                                    text={label}
                                    checked={value === participant?.sampleReceivedAtLab}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (
                                sampleReceivedAtLabOptions.find(
                                    (option) => option.value === participant?.sampleReceivedAtLab,
                                ) ?? sampleReceivedAtLabOptions[1]
                            ).label
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getLabStatusResultField = (): JSX.Element => {
        const labStatusResultOptions = [
            { label: t('admin.participantInfoModal.positive'), value: 'positive' },
            { label: t('admin.participantInfoModal.negative'), value: 'negative' },
            { label: t('admin.participantInfoModal.notAvailable'), value: 'n/a' },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.labStatusResult')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {labStatusResultOptions.map(({ label, value }) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateLabStatusResult(value)}
                                    name={value}
                                    id={value}
                                    value={value}
                                    text={label}
                                    checked={value === participant?.labStatusResult}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (
                                labStatusResultOptions.find(
                                    (option) => option.value === participant?.labStatusResult,
                                ) ?? labStatusResultOptions[2]
                            ).label
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getParticipatingToStudyField = (): JSX.Element => {
        const participatingToStudyOptions = [
            { label: t('admin.participantInfoModal.yes'), value: true },
            { label: t('admin.participantInfoModal.no'), value: false },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.participatingToStudy')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {participatingToStudyOptions.map(({ label, value }) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={stringValue}
                                    handleChange={() => updateParticipatingToStudy(value)}
                                    name={stringValue}
                                    id={stringValue}
                                    value={stringValue}
                                    text={label}
                                    checked={value === participant?.participatingToStudy}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (
                                participatingToStudyOptions.find(
                                    (option) => option.value === participant?.participatingToStudy,
                                ) ?? participatingToStudyOptions[2]
                            ).label
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getParticipatingToBloodSampleField = (): JSX.Element => {
        const participatingToBloodSampleOptions = [
            { label: t('admin.participantInfoModal.yes'), value: true },
            { label: t('admin.participantInfoModal.no'), value: false },
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.participatingToBloodSample')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {participatingToBloodSampleOptions.map(({ label, value }) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={stringValue}
                                    handleChange={() => updateParticipatingToBloodSample(value)}
                                    name={stringValue}
                                    id={stringValue}
                                    value={stringValue}
                                    text={label}
                                    checked={value === participant?.participatingToBloodSample}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    <Field
                        value={
                            (
                                participatingToBloodSampleOptions.find(
                                    (option) =>
                                        option.value === participant?.participatingToBloodSample,
                                ) ?? participatingToBloodSampleOptions[1]
                            ).label
                        }
                        disabled
                    />
                )}
            </FieldContainer>
        );
    };

    const getButtons = (): JSX.Element =>
        editMode ? (
            <ButtonsContainer>
                <Cancel
                    onClick={() => {
                        onEditMode(false);
                    }}
                >
                    {t('admin.cancel')}
                </Cancel>
                <Send
                    disabled={updateInProgress}
                    onClick={() => {
                        onUpdateForm();
                    }}
                >
                    {t('admin.confirm')}
                </Send>
                {success && <img src={SuccessIcon} alt="" />}
            </ButtonsContainer>
        ) : (
            <ButtonsContainer>
                <Cancel onClick={onClose}>{t('admin.cancel')}</Cancel>
                <Send
                    onClick={() => {
                        onEditMode(true);
                    }}
                >
                    {t('admin.edit')}
                </Send>
            </ButtonsContainer>
        );

    return authenticatedUser && participant ? (
        <ModalContainer>
            <Modal size={isHousehold ? 'large' : 'medium'}>
                {getCloseButton()}
                <Title>
                    {isUserHousehold(participant)
                        ? t('admin.participantInfoModal.title.household')
                        : participant.profile.firstName}
                </Title>
                <FieldsGroupContainer isChild={isChild}>
                    <FieldsGroup>
                        {isChild && getChildId()}
                        {isHousehold && getHouseholdId()}
                        {isChild && getAge()}
                        {isHousehold && getEnrollmentDateField()}
                        {getCompletionDateField()}
                        {isHousehold && getLanguageField()}
                    </FieldsGroup>
                    <FieldsGroup>
                        {getFirstNameField()}
                        {isHousehold && getLastNameField()}
                        {isChild && getBirthdayField()}
                        {isHousehold && getEmailField()}
                        {isHousehold && getPhoneField()}
                        {isHousehold && getFirstAddressLineField()}
                        {isHousehold && getSecondAddressLineField()}
                        {isHousehold && getCityField()}
                        {isHousehold && getProvinceField()}
                        {isHousehold && getPostalCodeField()}
                    </FieldsGroup>
                    <FieldsGroup>
                        {isHousehold && getTestKitSentField()}
                        {isHousehold && getSampleReceivedAtLabField()}
                        {isChild && getStatusField()}
                        {isChild && getLabStatusResultField()}
                        {isChild && getParticipatingToStudyField()}
                        {isChild && getParticipatingToBloodSampleField()}
                    </FieldsGroup>
                </FieldsGroupContainer>
                {!updateInProgress && authenticatedUserCanEdit && getButtons()}
                {updateInProgress && <Loader />}
            </Modal>
        </ModalContainer>
    ) : (
        <Loader />
    );
};
