import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Routes from './Routes';
import Loader from './components/Loader';
import { whoAmI, resetOldToken } from './slices/authSlice';

import './fonts.css';

function App() {
    const dispatch = useDispatch();
    resetOldToken();
    const token: string | null = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            dispatch(whoAmI());
        }
    }, [dispatch, token]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes />
        </Suspense>
    );
}

export default App;
