import validator from 'validator';
import { parse, isValid } from 'date-fns';

import { IUser, IUserProfile } from '../slices/userSlice';

const isNonEmptyString = (value: any): boolean => {
    return Boolean(isString(value) && value.length);
};

const isString = (value: any): boolean => {
    return Boolean(typeof value === 'string');
};

export const validateEmail = (email: IUser['email']): boolean => {
    return typeof email === 'string' && validator.isEmail(email);
};

export const validateFirstName = (firstName: IUserProfile['firstName']): boolean => {
    return isNonEmptyString(firstName);
};

export const validateLastName = (lastName: IUserProfile['lastName']): boolean => {
    return isNonEmptyString(lastName);
};

export const validateBirthday = (birthday: IUserProfile['birthday']): boolean => {
    try {
        if (birthday) {
            const parsedDate = parse(birthday, 'yyyy-MM-dd', new Date());
            const isValidDate = isValid(parsedDate);
            return isValidDate;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }
};

export const validateRole = (role: IUser['role']): boolean => {
    return Boolean(role === 'admin' || role === 'participant' || role === 'coordinator');
};

export const validatePostalCode = (postalCode: IUserProfile['postalCode']): boolean => {
    return isNonEmptyString(postalCode);
};

export const validateFirstAddressLine = (
    firstAddressLine: IUserProfile['firstAddressLine'],
): boolean => {
    return isNonEmptyString(firstAddressLine);
};

export const validateSecondAddressLine = (
    secondAddressLine: IUserProfile['secondAddressLine'],
): boolean => {
    return isString(secondAddressLine);
};

export const validateCity = (city: IUserProfile['city']): boolean => {
    return isNonEmptyString(city);
};

export const validateProvince = (province: IUserProfile['province']): boolean => {
    return isNonEmptyString(province);
};

export const validatePhone = (phone: IUserProfile['phone']): boolean => {
    return isNonEmptyString(phone);
};
