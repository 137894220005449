import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

import MinusGreyIcon from '../../assets/icons/minus_grey.svg';
import MinusPinkIcon from '../../assets/icons/minus_pink.svg';
import PlusGreyIcon from '../../assets/icons/plus_grey.svg';
import PlusPinkIcon from '../../assets/icons/plus_pink.svg';

interface INumberInputContainer {
    margin?: string;
}

export const Limits = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    padding: 0px;
    text-align: left;

    @media ${sizes.XP} {
        font-size: 18px;
    }

    @media ${sizes.G} {
        font-size: 20px;
    }
`;

export const NumberInputContainer = styled.div<INumberInputContainer>`
    display: grid;
    grid-template-columns: 25% auto 25%;
    width: 200px;
    height: 50px;
    margin: ${(props) => (props.margin ? props.margin : '0')};
`;

export const Minus = styled.button`
    border-radius: 6px 0 0 6px;
    border-right: none;
    background-image: url(${MinusGreyIcon});
    background-repeat: no-repeat;
    background-position: center;

    :hover {
        background-image: url(${MinusPinkIcon});
    }
`;

export const Plus = styled.button`
    border-radius: 0 6px 6px 0;
    border-left: none;
    background-image: url(${PlusGreyIcon});
    background-repeat: no-repeat;
    background-position: center;

    :hover {
        background-image: url(${PlusPinkIcon});
    }
`;

export const NumberInput = styled.input`
    font-family: PoppinsRegular;
    border-right: none;
    border-left: none;
    text-align: center;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
`;

export const WeightHeightContainer = styled.div`
    display: grid;
    place-items: center;
    height: 35%;
`;

export const MetricSelectorContainer = styled.div`
    display: grid;
    place-items: center;
    grid-template-columns: 50% 50%;
    width: 80%;
`;

export const NumberFieldContainer = styled.div`
    display: grid;
    place-items: center;
    grid-template-rows: auto auto;
    width: 100%;
`;

export const Rounded = styled.div`
    font-family: 'PoppinsRegular';
    font-size: 14px;
    color: ${colours.darkGrey};
`;
