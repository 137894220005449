import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const HelpInfoContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    place-items: center;
    color: ${colours.purple};
    font-family: NunitoBold;
    font-size: 14px;

    @media ${sizes.M} {
        font-size: 20px;
    }
`;

export interface HelpInfoColumnProps {
    border?: boolean;
}

export const HelpInfoColumn = styled.div<HelpInfoColumnProps>`
    padding: 0 20px;
    border-left: ${(props) => (props.border ? `solid 1px` : `none`)};
`;

export const HelpInfoLines = styled.div`
    display: grid;
    align-items: center;
    justify-items: self-start;
    grid-template-columns: 20px auto;
    grid-gap: 10px;
`;

export const HelpInfos = styled.a`
    color: ${colours.purple};
    text-decoration: none;
`;

export const Icon = styled.img`
    max-height: 20px;
    width: 20px;
`;
