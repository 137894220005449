import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Layouts
import HomeLayout from '../../layouts/home';

// Styles
import {
    ThankYouContainer,
    Text1,
    Text2,
    YouTubeIcon,
    BloodSampleVideo,
    Photo,
    LoginPage,
} from './styles';
import { Title, Link } from '../../styles/common';

// Constants
import LANGUAGES from '../../constants/languages';
import LINKS from '../../constants/links';
import { ROUTES } from '../../constants/routes';

// Utils
import { logoutUser } from '../../slices/authSlice';

const ThankYou: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const encoreStudyLink =
        language === LANGUAGES.EN ? LINKS.ENCOREWEBSITE_EN : LINKS.ENCOREWEBSITE_FR;

    const bloodSampleVideoLink =
        language === LANGUAGES.EN ? LINKS.BLOODVIDEOSAMPLELINE_EN : LINKS.BLOODVIDEOSAMPLELINE_FR;

    const goToLogin = () => {
        dispatch(logoutUser());
        history.push(ROUTES.LOGIN);
    };

    return (
        <HomeLayout>
            <Helmet>
                <title>{t('thankyou.helmet')}</title>
            </Helmet>
            <ThankYouContainer>
                <Title>{t('thankyou.title')}</Title>
                <Photo></Photo>
                <Text1>{t('thankyou.text1')}</Text1>
                <BloodSampleVideo href={bloodSampleVideoLink} target="_blank">
                    <YouTubeIcon />
                    {t('thankyou.bloodSample')}
                </BloodSampleVideo>
                <LoginPage onClick={goToLogin}>{t('login.clickHereLogin')}</LoginPage>
                <Text2>
                    <Trans i18nKey="thankyou.text2">
                        Check out our daily update at
                        <Link href={encoreStudyLink} target="_blank">
                            encorestudy.ca
                        </Link>
                    </Trans>
                </Text2>
            </ThankYouContainer>
        </HomeLayout>
    );
};

export default ThankYou;
