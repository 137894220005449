import { configureStore, Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import userReducer from './slices/userSlice';
import surveyReducer from './slices/surveySlice';
import authReducer from './slices/authSlice';
import createPasswordAndProfileReducer from './slices/createPasswordAndProfile';
import getHouseholdInfoReducer from './slices/getHouseholdSlice';
import setChildReducer from './slices/childSlice';

const rootReducer = combineReducers({
    user: userReducer,
    survey: surveyReducer,
    auth: authReducer,
    createPasswordAndProfile: createPasswordAndProfileReducer,
    household: getHouseholdInfoReducer,
    child: setChildReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
