import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const LoginField = styled.input`
    justify-self: center;
    font-family: PoppinsRegular;
    width: 100%;
    height: 48px;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;
`;

export const Error = styled.span`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const Label = styled.label`
    display: block;
    text-align: left;
`;

export const Fields = styled.div`
    justify-self: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const WelcomeTextContainer = styled.div`
    @media ${sizes.XP} {
        margin: 10px 20px 40px;
    }

    @media ${sizes.M} {
        margin: 25px;
    }
`;

export const Text = styled.div`
    margin: 15px 0;
`;

export const ResetLink = styled(Link)`
    font-family: PoppinsMedium;
    color: ${colours.purple};
    text-decoration: none;
`;

export const Container = styled.div`
    display: grid;
    place-items: center;
`;

export const EmailLink = styled.a`
    font-family: NunitoSemiBold;
    color: ${colours.purple};
    text-decoration: none;
`;
