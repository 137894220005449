import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format as formatDate } from 'date-fns';

import CloseIcon from '../../assets/icons/close_icon.svg';
import { IUser } from '../../slices/userSlice';

import { RadioOption } from '../RadioOption';

import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    FieldContainer,
    ButtonsContainer,
    Field,
} from '../../styles/modal';
import { NoteContainer } from './styles';
import { RadioGroup, Error } from '../../styles/common';

import { calculateAge } from '../../utils/date';

export interface IProps {
    child: IUser;
    error: boolean;
    onCloseButton: () => void;
    onSaveButton: (
        participatingToStudy: IUser['participatingToStudy'],
        participatingToBloodSample: IUser['participatingToBloodSample'],
    ) => void;
}

export const ChildConsentModal: React.FC<IProps> = ({
    child,
    error,
    onCloseButton,
    onSaveButton,
}) => {
    const { t } = useTranslation();

    const [participatingToBloodSample, setParticipatingToBloodSample] = useState<boolean>();

    const age = child.profile.birthday ? calculateAge(child.profile.birthday) : null;

    const firstWaveDate = formatDate(new Date(String(child.firstWaveDate)), 'yyyy-MM-dd');

    const participatingOptions = [
        {
            label: t('parentDashboard.yes'),
            value: true,
        },
        { label: t('parentDashboard.no'), value: false },
    ];

    const saveChildOptions = () => {
        if (participatingToBloodSample !== undefined) {
            onSaveButton(true, participatingToBloodSample);
        }
    };

    return (
        <ModalContainer>
            <Modal>
                <CloseButton onClick={() => onCloseButton()}>
                    <img src={CloseIcon} alt="close" />
                </CloseButton>
                <Title>{t('parentDashboard.signUp')}</Title>
                <FieldContainer inline>
                    <Label>{t('parentDashboard.childFirstName')}</Label>
                    <Field value={child.profile.firstName} disabled />
                </FieldContainer>
                <FieldContainer inline>
                    <Label>{t('parentDashboard.age')}</Label>
                    <Field value={`${age} ${t('parentDashboard.years')}`} disabled />
                </FieldContainer>
                <FieldContainer inline>
                    <Label>{t('parentDashboard.firstWaveDate')}</Label>
                    <Field value={firstWaveDate} disabled />
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('parentDashboard.participatingToBloodSample')}</Label>
                    <RadioGroup>
                        {participatingOptions.map(({ label, value }) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={stringValue}
                                    handleChange={() => setParticipatingToBloodSample(value)}
                                    name={label}
                                    id={label}
                                    value={label}
                                    text={label}
                                    checked={participatingToBloodSample === value}
                                    disabled={false}
                                />
                            );
                        })}
                    </RadioGroup>
                </FieldContainer>
                <NoteContainer>{t('survey.bloodPrickWarning')}</NoteContainer>
                <ButtonsContainer>
                    <Cancel onClick={() => onCloseButton()}>{t('admin.cancel')}</Cancel>
                    <Send onClick={saveChildOptions}>{t('admin.send')}</Send>
                </ButtonsContainer>
                {error && <Error className="textRight">{t('parentDashboard.updateError')}</Error>}
            </Modal>
        </ModalContainer>
    );
};
