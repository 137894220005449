import React from 'react';

// Styles
import { MultitypeContainer, QuestionnaireContainer } from './styles';

import { QuestionOptionType } from '../../slices/surveySlice';
import { TextfieldQuestion } from '../TextfieldQuestion';
import { IUserProfile } from '../../slices/userSlice';

export interface IProps {
    question: number;
    options?: QuestionOptionType[];
    profile?: IUserProfile;
}

export const ConfirmAddress: React.FunctionComponent<IProps> = ({ question, options, profile }) => {
    const indexedOptions = options?.reduce((acc, ele) => {
        acc[ele.text] = ele;
        return acc;
    }, {} as Record<string, QuestionOptionType>);

    const getMask = (field: string) => {
        if (field === 'postalCodeQuestion') {
            return 'a9a9a9';
        } else if (field === 'phone') {
            return '(999) 999-9999';
        }
        return '';
    };

    const displayField = (element: QuestionOptionType, value: string, index: number) => {
        return (
            <TextfieldQuestion
                question={question}
                index={index}
                value={value}
                questionOptionId={element.id}
                text={element.text}
                key={element.id}
                mask={getMask(element.text)}
            />
        );
    };

    if (!indexedOptions) {
        return <>No valid options in the address validation form</>;
    }

    return (
        <MultitypeContainer>
            <QuestionnaireContainer>
                {displayField(indexedOptions.address1, profile?.firstAddressLine ?? '', 1)}
                {displayField(indexedOptions.address2, profile?.secondAddressLine ?? '', 1)}
                {displayField(indexedOptions.postalCodeQuestion, profile?.postalCode ?? '', 1)}
                {displayField(indexedOptions.city, profile?.city ?? '', 1)}
                {displayField(indexedOptions.province, profile?.province ?? '', 1)}
            </QuestionnaireContainer>
        </MultitypeContainer>
    );
};
