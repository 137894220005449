import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { selectAuthenticatedUser } from './slices/authSlice';
import { ROUTES } from './constants/routes';

import { isUserAdminOrCoordinator } from './utils/roles-check';

const PrivateRoute: React.FC<RouteProps & { isAdminRoute?: boolean }> = ({
    children,
    isAdminRoute,
    ...rest
}) => {
    const user = useSelector(selectAuthenticatedUser);

    const renderRedirect = (pathname: string) => {
        return (
            <Route
                {...rest}
                render={({ location }) => (
                    <Redirect
                        to={{
                            pathname,
                            state: { from: location },
                        }}
                    />
                )}
            />
        );
    };

    // If admin trying to access non admin route
    if (!isAdminRoute && user && isUserAdminOrCoordinator(user)) {
        return renderRedirect(ROUTES.ADMIN);
    }

    // If non-admin trying to access admin route
    if (isAdminRoute && user && !isUserAdminOrCoordinator(user)) {
        return renderRedirect(ROUTES.PARENT_DASHBOARD);
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem('token') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
