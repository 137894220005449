/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import { OptionContainer, OptionLabel, OptionsContainer } from '../CheckboxQuestion/styles';
import { CustomRadio, HiddenRadio, Label } from './styles';
import { Question } from '../../constants/questionTypes';
import { Input, InputContainer, InputTitle } from '../SelectboxQuestion/styles';

export interface IProps {
    question: number;
    text: string;
    index?: number;
    options: QuestionOptionType[];
}
export const RadioQuestion: React.FC<IProps> = ({ question, text, index, options }) => {
    const [answer, setAnswer] = useState<number>(-1);
    const [customAnswer, setCustomAnswer] = useState<string>();
    const [isCustomAnswer, setIsCustomAnswer] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleChange = (question: number, id: number, custom: boolean) => {
        if (custom) {
            setIsCustomAnswer(true);
            setAnswer(id);
        }
        if (!custom) {
            setIsCustomAnswer(false);
            setAnswer(id);
            dispatch(
                saveObject({
                    question_id: question,
                    answer_offered_id: id,
                    order: index,
                    type: Question.radio,
                }),
            );
        }
    };
    const handleCustomAnswerChange = (question: number, value: string) => {
        setCustomAnswer(value);
        dispatch(
            saveObject({
                question_id: question,
                answer_offered_id: answer,
                custom_answer: value,
            }),
        );
    };

    useEffect(() => {
        let selectedOption = options.filter((option) => option.selected.length > 0);

        if (index) {
            selectedOption = selectedOption.filter(
                (option) => option.selected.findIndex((elm: any) => elm.order === index) !== -1,
            );
        }

        if (selectedOption.length > 0) {
            setAnswer(selectedOption[0].id);
            if (selectedOption[0].customAnswer) {
                setIsCustomAnswer(true);
                setCustomAnswer(
                    (((selectedOption[0].selected ?? [])[0] ?? {}).custom_answer ?? {}).answerText,
                );
            }
            dispatch(
                saveObject({
                    question_id: question,
                    answer_offered_id: selectedOption[0].id,
                    order: index,
                    type: Question.radio,
                    custom_answer: customAnswer,
                }),
            );
        }
    }, [options, index]);

    return (
        <OptionsContainer>
            {index && <Label>{t(`survey.${text}`)}</Label>}
            {!index && <Label>{t(`survey.selectOne`)}</Label>}
            {options.map((option) => {
                return (
                    <OptionContainer key={option.text}>
                        <CustomRadio
                            checked={answer === option.id}
                            onClick={() => handleChange(question, option.id, option.customAnswer)}
                        />
                        <HiddenRadio
                            type="radio"
                            name={text}
                            id={option.text}
                            value={option.text}
                            onChange={() => handleChange(question, option.id, option.customAnswer)}
                        />
                        <OptionLabel htmlFor={option.text}>
                            {t(`survey.${option.text}`)}
                        </OptionLabel>
                    </OptionContainer>
                );
            })}
            {isCustomAnswer && (
                <InputContainer>
                    <InputTitle>{t(`survey.specify`)}</InputTitle>
                    <Input
                        type="text"
                        name={text}
                        placeholder={t(`survey.typetexthere`)}
                        value={customAnswer}
                        onChange={(event) => handleCustomAnswerChange(question, event.target.value)}
                    />
                </InputContainer>
            )}
        </OptionsContainer>
    );
};
