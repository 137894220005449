import styled from 'styled-components';

import { colours } from '../constants/colours';
import { sizes } from '../constants/mediaSizes';

export const DropDownBorder = `1px solid ${colours.mediumGrey}`;

export const boxShadowUp = `-3px -4px 18px ${colours.dropShadow}`;

export interface IIconComponent {
    hideIcon?: boolean;
}
export interface ButtonStyleProps {
    active: boolean;
    justifySelf?: string;
    largeText?: boolean;
}

export enum DashboardType {
    admin = 'admin',
    household = 'household',
}
export interface DashboardProps {
    type?: string;
}

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 332px;
`;

export const RadioGroupCustom = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
`;

export const FieldError = styled.div`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const StyledButton = styled.button<
    React.HTMLAttributes<HTMLButtonElement> & ButtonStyleProps
>`
    background-color: ${(props) => (props.active ? colours.purple : colours.mediumGrey)};
    justify-self: ${(props) => (props.justifySelf ? props.justifySelf : 'start')};
    color: ${colours.white};
    font-family: NunitoBold;
    text-transform: uppercase;
    border-radius: 20px;
    border: none;
    pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
    font-size: 20px;
    margin: 15px 0;
    padding: 15px 30px;

    :hover {
        background-color: ${(props) => (props.active ? colours.purpleHover : colours.mediumGrey)};
    }

    @media ${sizes.XP} {
        min-width: 167px;
        height: 54px;
        font-size: 20px;
        margin: 30px;
    }

    @media ${sizes.G} {
        width: 350px;
        height: 64px;
        padding: 0;
        font-size: ${(props) => (props.largeText ? '20px' : '32px')};
    }
`;

export const LanguageSwitch = styled.button`
    height: 77px;
    width: 43px;
    background-color: ${colours.purple};
    color: white;
    border: none;
    cursor: pointer;
    font-family: NunitoBold;
    justify-self: flex-end;

    :hover {
        background-color: ${colours.purpleHover};
    }
`;

export const Logo = styled.img`
    justify-self: center;
    margin: 3px 0 12px 0;

    @media ${sizes.M} {
        margin: 0;
    }

    @media ${sizes.XP} {
        width: 179px;
    }

    @media ${sizes.G} {
        width: 262px;
    }
`;

export const Title = styled.div`
    font-family: NunitoBold;
    color: ${colours.purple};
    text-align: center;

    @media ${sizes.XP} {
        font-size: 24px;
        padding-top: 15px;
    }

    @media ${sizes.M} {
        font-size: 32px;
    }
`;

export const DashboardPageContainer = styled.div<DashboardProps>`
    display: grid;
    height: 100vh;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: ${(props) =>
            props.type === DashboardType.household
                ? '120px auto'
                : '120px 120px 70px auto 70px 70px'};
    }

    @media ${sizes.G} {
        grid-template-rows: ${(props) =>
            props.type === DashboardType.household ? '77px auto' : '77px 70px 70px auto 77px 77px'};
    }
`;

export const Error = styled.span`
    color: ${colours.errorRed};

    &.textRight {
        text-align: right;
    }
`;

export const Link = styled.a`
    font-family: PoppinsMedium;
    color: ${colours.purple};
    text-decoration: none;
`;
