import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';

import { ServerError } from '../types/error';

export interface ICreatePasswordAndProfileState {
    success: boolean | null;
    error: ServerError;
    inProgress: boolean;
}

export const initialState: ICreatePasswordAndProfileState = {
    success: null,
    error: null,
    inProgress: false,
};

export const clearCreatePasswordAndProfile = createAsyncThunk(
    'create/user/clear',
    (params: null = null, { dispatch }) => {
        dispatch(clear());
    },
);

export const createPasswordAndProfile = createAsyncThunk(
    'createPasswordAndProfile/user',
    async (
        params: {
            token: string;
            user: {
                password: string;
                profile: {
                    firstName: string;
                    lastName: string;
                    postalCode: string;
                    firstAddressLine: string;
                    secondAddressLine: string;
                    city: string;
                    province: string;
                    phone: string;
                };
            };
        },
        { dispatch, rejectWithValue },
    ) => {
        dispatch(setCreatePasswordAndProfileInProgress(true));

        const profile = params.user.profile;

        const token = params.token;
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }

        try {
            const { status } = await axios.post(BACKEND_ROUTES.PASSWORD_AND_PROFILE_RESET, {
                token,
                user: {
                    password: params.user.password,
                    profile: {
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        postalCode: profile.postalCode,
                        firstAddressLine: profile.firstAddressLine,
                        secondAddressLine: profile.secondAddressLine,
                        city: profile.city,
                        province: profile.province,
                        phone: profile.phone,
                    },
                },
            });

            if (status === 204) {
                dispatch(setCreatePasswordAndProfileSuccess(true));
            }
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 400) {
                errorMessage = 'Required fields missing';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }

            dispatch(setCreatePasswordAndProfileErrorAction(errorMessage));
            return rejectWithValue({ message: errorMessage });
        }
    },
);

export const slice = createSlice({
    name: 'createPasswordAndProfile',
    initialState,
    reducers: {
        setCreatePasswordAndProfileSuccess(state, action) {
            state.success = action.payload;
            state.error = null;
            state.inProgress = false;
        },
        setCreatePasswordAndProfileErrorAction(state, action) {
            state.success = null;
            state.error = action.payload;
            state.inProgress = false;
        },
        setCreatePasswordAndProfileInProgress(state, action) {
            state.success = null;
            state.error = null;
            state.inProgress = action.payload;
        },
        clear(state) {
            state.success = null;
            state.error = null;
            state.inProgress = false;
        },
    },
});

export const selectCreatePasswordAndProfile = (state: RootState) =>
    state.createPasswordAndProfile.success;
export const selectCreatePasswordAndProfileError = (state: RootState) =>
    state.createPasswordAndProfile.error;

export const {
    setCreatePasswordAndProfileSuccess,
    setCreatePasswordAndProfileInProgress,
    setCreatePasswordAndProfileErrorAction,
    clear,
} = slice.actions;

export default slice.reducer;
