/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import * as _ from 'lodash';

import { Question } from '../constants/questionTypes';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import { IUser } from './userSlice';
import { ServerError } from '../types/error';

export interface IUserId {
    userId?: number;
}
export interface QuestionChildType {
    id: number;
    text: string;
    customAnswer: boolean;
    questionId: number | null;
    questionTypeId: number | null;
    questionOptionParentId: number | null;
    selected: any;
}
export interface QuestionOptionType {
    id: number;
    text: string;
    customAnswer: boolean;
    answer: any;
    questionId: number | null;
    questionTypeId: number | null;
    questionOptionParentId: number | null;
    question_child: QuestionChildType[];
    min?: number;
    max?: number;
    selected: any;
}
export interface QuestionType {
    id: number;
    text: string;
    order: number;
    sectionId?: number;
    isFirstQuestionOfSection?: boolean;
    depends_on_question: QuestionType;
    section: SectionType;
    questionTypeId?: number;
    conditional_question?: QuestionType;
    answer: any;
    totalQuestions: number;
    totalSectionQuestions: number;
    min: number | null;
    max: number | null;
    subtype: string | null;
    question_option: QuestionOptionType[];
    question_type: {
        id: number;
        type: string;
    };
}
export interface SectionType {
    id: number;
    name: string;
    type: string;
    order: number;
    surveyId: number;
    question: QuestionType[];
}
export interface UserSurveyFormDataType {
    id?: number;
    userId?: IUser['id'];
    surveyId?: number;
    currentQuestionId?: number | null;
    completedAt?: string | null;
}
export interface ISurveyState {
    question?: QuestionType;
    error?: ServerError;
    loading: boolean;
    saveObject: any;
    currentQuestion: number;
}
export const initialState: ISurveyState = {
    loading: false,
    saveObject: [],
    currentQuestion: 1,
};

export const getCurrentQuestion = createAsyncThunk<
    QuestionType,
    IUserId,
    { rejectValue: ServerError }
>('get/survey', async (userId: IUserId, { rejectWithValue }) => {
    const token: string | null = localStorage.getItem('token');
    const config: {
        headers?: {};
    } = {};
    if (token) {
        config.headers = {
            authorization: `Bearer ${token}`,
        };
    }
    try {
        const currentQuestion = await axios.get<{ data: QuestionType }>(
            `${BACKEND_ROUTES.NEXT_QUESTION}/${userId.userId}`,
            config,
        );
        return currentQuestion.data.data;
    } catch (e) {
        if (e.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
        }

        // todo add a route to login here
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue({ message: error.response.data });
        }
        return rejectWithValue({ message: error.message });
    }
});

export const saveQuestion = createAsyncThunk<
    any,
    { increment: boolean; userId: number | undefined },
    { rejectValue: ServerError }
>('save/survey', async ({ increment, userId }, { rejectWithValue, getState, dispatch }) => {
    const token: string | null = localStorage.getItem('token');
    const config: {
        headers?: {};
    } = {};
    if (token) {
        config.headers = {
            authorization: `Bearer ${token}`,
        };
    }

    const state: any = getState();

    if (!_.isEmpty(state.survey.saveObject)) {
        try {
            await axios.post(
                `${BACKEND_ROUTES.ANSWER}/${userId}`,
                // increment is passed so the backend updates the user's currentQuestion or not
                { answer: state.survey.saveObject, shouldUpdateUserCurrentAnswer: increment },
                config,
            );
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue({ message: error.response.data });
            }
            return rejectWithValue({ message: error.message });
        }
    }

    let currentQuestion;

    try {
        if (increment) {
            currentQuestion = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.NEXT_QUESTION}/${userId}`,
                config,
            );
        } else {
            currentQuestion = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.PREVIOUS_QUESTION}/${userId}`,
                config,
            );
        }
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue({ message: error.response.data });
        }
        return rejectWithValue({ message: error.message });
    }

    dispatch(saveObject([]));

    return currentQuestion.data.data;
});

export const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        saveObject(state, action: PayloadAction<any>) {
            const { payload } = action;

            if (_.isEmpty(payload)) {
                state.saveObject = [];
            }

            // if its an array and checkbox, its coming from a customAnswer
            if (Array.isArray(payload) && payload.length > 0 && payload[0].type === 'checkbox') {
                // get all the old elements from the checkbox and the other question types
                const newOrderElements = state.saveObject.filter(
                    (elm: any) =>
                        (elm.order !== payload[0].order && elm.type === 'checkbox') ||
                        elm.type !== 'checkbox',
                );

                // and push they new values back
                for (const element of payload) {
                    newOrderElements.push(element);
                    state.saveObject = [...newOrderElements];
                }
            } else if (!payload.order && payload.type === Question.checkbox) {
                const index = state.saveObject.findIndex((elm: any) => {
                    return (
                        elm.question_id === payload.question_id &&
                        elm.type === payload.type &&
                        elm.answer_offered_id === payload.answer_offered_id
                    );
                });
                if (index === -1) {
                    state.saveObject = [...state.saveObject, payload];
                } else {
                    state.saveObject = state.saveObject.filter(
                        (elm: any, i: number) => i !== index,
                    );
                }
            } else if (
                (typeof payload.order !== 'undefined' || payload.type === Question.checkbox) &&
                payload.answer_offered_id &&
                payload.question_id
            ) {
                const index = state.saveObject.findIndex((elm: any) => {
                    if (payload.type === Question.radio) {
                        return (
                            elm.question_id === payload.question_id &&
                            elm.order === payload.order &&
                            elm.type === payload.type
                        );
                    }
                    return (
                        elm.question_id === payload.question_id &&
                        elm.order === payload.order &&
                        elm.type === payload.type &&
                        elm.answer_offered_id === payload.answer_offered_id
                    );
                });

                if (index === -1) {
                    state.saveObject = [...state.saveObject, payload];
                } else {
                    if (payload.type === Question.checkbox) {
                        state.saveObject = state.saveObject.filter(
                            (elm: any, i: number) => i !== index,
                        );
                    } else {
                        state.saveObject[index] = {
                            ...state.saveObject[index],
                            answer: payload.answer,
                            answer_offered_id: payload.answer_offered_id,
                        };
                    }
                }
            } else {
                state.saveObject = Array.isArray(payload) ? payload : [payload];
            }
        },
        setCurrentQuestion(state, action: PayloadAction<any>) {
            const { payload } = action;
            state.currentQuestion = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getCurrentQuestion.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getCurrentQuestion.fulfilled, (state, { payload }) => {
            state.question = payload;
            state.loading = false;
        });
        builder.addCase(getCurrentQuestion.rejected, (state, { payload }) => {
            if (payload) {
                state.error = payload;
                state.loading = false;
            }
        });
        builder.addCase(saveQuestion.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(saveQuestion.fulfilled, (state, { payload }) => {
            state.question = { ...payload };
            state.loading = false;
        });
        builder.addCase(saveQuestion.rejected, (state, { payload }) => {
            if (payload) {
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export const selectQuestion = (state: RootState) => state.survey.question;

export const { saveObject, setCurrentQuestion } = slice.actions;

export default slice.reducer;
