import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 55% auto;
    place-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    font-family: NunitoBold;
    font-size: 24px;
    position: sticky;
    top: 0;
    background-color: ${colours.white};
    z-index: 10;

    @media ${sizes.XP} {
        font-size: 22px;
    }

    @media ${sizes.G} {
        grid-template-columns: auto auto;
    }

    ::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: solid 4px ${colours.lightGrey};
        width: 100%;
    }
`;

export const HeaderLogo = styled.img`
    cursor: pointer;
    display: revert;
    height: 50px;
    margin-left: 15px;
`;

export const UserContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    place-items: center;
    grid-gap: 15px;
    justify-self: end;

    @media ${sizes.M} {
        margin-right: 20px;
    }
`;

export const UserName = styled.div`
    display: none;

    @media ${sizes.M} {
        display: revert;
        font-family: PoppinsRegular;
        font-size: 16px;
    }
`;

export const Icon = styled.img`
    width: 50px;
`;

export const Arrow = styled.img`
    width: 15px;
`;

export const Spacer = styled.div`
    display: none;

    @media ${sizes.M} {
        display: revert;
    }
`;

export const AdminMenu = styled.div`
    border: 2px solid ${colours.mediumGrey};
    width: 232px;
    height: 169px;
    z-index: 15;
    display: grid;
    align-items: center;
    font-family: 'PoppinsRegular';
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 4px 4px ${colours.lightGrey};
    position: absolute;
    top: 120px;
    right: 20px;
    background-color: ${colours.white};

    @media ${sizes.G} {
        top: 77px;
    }
`;
