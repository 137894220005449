import * as dayjs from 'dayjs';
import { differenceInYears } from 'date-fns';

export const formatDate = (date?: string) => {
    if (!date) {
        return '';
    }
    return dayjs.default(date).format('DD/MM/YYYY');
};

export const correctTimeZone = (date?: string) => {
    if (!date) {
        return '';
    }

    if (date.charAt(date.length - 1) === 'Z') {
        return date.slice(0, -1);
    }

    return date;
};

export const calculateAge = (birthday: string) => differenceInYears(new Date(), new Date(birthday));
