const { REACT_APP_BASE_URL, REACT_APP_API_PREFIX } = process.env;
const API_URL = `${REACT_APP_BASE_URL}/${REACT_APP_API_PREFIX}`;

export const ROUTES = {
    LOGIN: '/login',
    SURVEY: '/survey',
    ADMIN: '/admin',
    HOME: '/',
    CONSENT: '/consent',
    PROFILE: '/profile/:token',
    THANK_YOU: '/thankyou',
    SIGNUP_CONFIRMATION: '/signup-confirmation',
    RESET_PASSWORD: '/reset-password',
    WELCOME_BACK: '/welcome-back',
    PARENT_DASHBOARD: '/dashboard',
};

export const BACKEND_ROUTES = {
    BASE_URL: `${REACT_APP_BASE_URL}`,
    LOGIN: `${API_URL}/auth/login`,
    LOGOUT: `${API_URL}/auth/logout`,
    WHOAMI: `${API_URL}/auth/whoami`,
    SURVEY: `${API_URL}/survey/json`,
    SURVEY_DB: `${API_URL}/survey`,
    ANSWER: `${API_URL}/survey/answer`,
    NEXT_QUESTION: `${API_URL}/survey/next`,
    SIGNUP: `${API_URL}/users/signup`,
    RESET_PASSWORD: `${API_URL}/users/reset-password`,
    SEND_RESET_PASSWORD: `${API_URL}/users/send-password-reset`,
    SEND_WELCOME_EMAIL: `${API_URL}/users/send-welcome-email`,
    USERS: `${API_URL}/users`,
    IMPORT_USERS: `${API_URL}/users/import-csv`,
    CHECK_PASSWORD: `${API_URL}/auth/check-password`,
    PREVIOUS_QUESTION: `${API_URL}/survey/previous`,
    DOWNLOAD_PARTICIPANTS_DATA: `${API_URL}/users/download-csv`,
    DOWNLOAD_SURVEY_DATA_HOUSEHOLDS_AND_CHILDREN: `${API_URL}/survey/download-csv/households-children`,
    DOWNLOAD_SURVEY_DATA_TEENAGERS: `${API_URL}/survey/download-csv/teenagers`,
    PASSWORD_AND_PROFILE_RESET: `${API_URL}/users/reset-password-and-profile`,
    HOUSEHOLD: `${API_URL}/users/household`,
};
