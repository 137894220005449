import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 332px;
    line-height: 34px;
`;

export const FieldsGroup = styled.div`
    display: grid;
    column-gap: 30px;

    @media ${sizes.M} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${sizes.XG} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const FieldsGroupContainer = styled.div<{ isChild: boolean }>`
    display: grid;
    grid-template-rows: auto;

    > ${FieldsGroup} {
        ${({ isChild }) =>
            isChild &&
            `
        @media ${sizes.XG} {
            grid-template-columns: 1fr 1fr;
        }
        `}
    }
`;
