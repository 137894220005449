/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import validator from 'validator';

import { logoutUser } from '../../slices/authSlice';

import {
    BodyContainer,
    ButtonContainer,
    FooterContainer,
    LogoutButton,
    NavNextButton,
    NavPreviousButton,
} from './styles';
import { RootState } from '../../store';
import { QuestionType } from '../../slices/surveySlice';

import { ROUTES } from '../../constants/routes';
import { QuestionTypeId } from '../../constants/questionTypes';

export interface IProps {
    question?: QuestionType;
    section?: number;
    onNext?: () => void;
    onPrevious?: () => void;
    hasAnswer: boolean;
    goTo?: string;
    isLastQuestion?: boolean;
}

export const Footer: React.FunctionComponent<IProps> = ({
    question,
    section,
    onNext,
    onPrevious,
    hasAnswer,
    goTo,
    isLastQuestion,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const objSaveObject = useSelector((state: RootState) => state.survey.saveObject);
    const customNextHandler = () => {
        if (
            question?.text === 'requiredPortionCompleted' &&
            objSaveObject[0].answer_offered_id !== 2001001 // meaning the user chose either 2001002 or 2001003
        ) {
            history.push('/dashboard');
        }
        return onNext?.();
    };
    const isButtonDisabled = () => {
        let isValid = false;
        switch (question?.questionTypeId) {
            case QuestionTypeId.text:
                isValid =
                    (objSaveObject.length > 0 &&
                        objSaveObject[0].answer !== '' &&
                        !validator.contains(objSaveObject[0].answer, '_')) ||
                    hasAnswer;
                break;
            case QuestionTypeId.select:
                if (objSaveObject.length > 0 && !objSaveObject[0].is_custom) {
                    isValid = objSaveObject.length > 0 && objSaveObject[0].answer_offered_id;
                } else {
                    isValid = objSaveObject.length > 0 && objSaveObject[0].custom_answer;
                }

                break;
            case QuestionTypeId.date:
                isValid = objSaveObject.length > 0 && validator.isDate(objSaveObject[0].answer);
                break;
            case QuestionTypeId.multitype:
                if (question.id === 2070) {
                    isValid = true;
                    break;
                }
                const answer = question?.conditional_question?.answer[0]?.answer;
                const answerTypes = objSaveObject.map((answer: any) => {
                    return {
                        type: answer.type,
                        index: answer.order,
                    };
                });
                const uniqueAnswerTypes: any = [];
                for (const typeObj of answerTypes) {
                    if (
                        uniqueAnswerTypes.filter(
                            (elm: any) => elm.type === typeObj.type && elm.index === typeObj.index,
                        ).length === 0 ||
                        typeObj.type !== 'checkbox'
                    ) {
                        uniqueAnswerTypes.push(typeObj);
                    }
                }

                isValid = uniqueAnswerTypes.length >= question.question_option.length * answer;
                break;
            case QuestionTypeId.multiradio:
                // by default, just checks if we answered all the questions
                isValid = objSaveObject.length === question.question_option.length;
                break;
            default:
                isValid = objSaveObject.length > 0 || hasAnswer;
        }

        return !isValid;
    };

    if (question && section) {
        return (
            <BodyContainer>
                <FooterContainer>
                    {question.id > 1 && (
                        <NavPreviousButton
                            onClick={onPrevious}
                            active={question.id > 1 && !question.isFirstQuestionOfSection}
                        >
                            {t('footer.previous')}
                        </NavPreviousButton>
                    )}
                    {!isLastQuestion && (
                        <NavNextButton
                            onClick={customNextHandler}
                            active={!isButtonDisabled()}
                            disabled={isButtonDisabled()}
                        >
                            {t('footer.next')}
                        </NavNextButton>
                    )}
                    {isLastQuestion && (
                        <ButtonContainer to={ROUTES.THANK_YOU}>
                            <NavNextButton onClick={onNext} active={hasAnswer}>
                                {t('footer.next')}
                            </NavNextButton>
                        </ButtonContainer>
                    )}
                </FooterContainer>
                <LogoutButton
                    onClick={() => {
                        dispatch(logoutUser());
                        history.push('/login');
                    }}
                >
                    {t('header.saveAndContinueLater')}
                </LogoutButton>
            </BodyContainer>
        );
    }

    if (goTo) {
        return (
            <FooterContainer>
                <ButtonContainer to={hasAnswer ? goTo : '#'}>
                    <NavNextButton onClick={onNext} active={hasAnswer}>
                        {t('footer.next')}
                    </NavNextButton>
                </ButtonContainer>
            </FooterContainer>
        );
    }

    return <FooterContainer></FooterContainer>;
};
