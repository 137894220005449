import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

import DesktopNormal from '../../assets/icons/checkbox_normal_desktop.svg';
import DesktopChecked from '../../assets/icons/checkbox_checked_desktop.svg';
import MobileNormal from '../../assets/icons/checkbox_normal_mobile.svg';
import MobileChecked from '../../assets/icons/checkbox_checked_mobile.svg';

export const CheckboxContainer = styled.div`
    margin: 20px 0;
`;

export const Subtitle = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    text-align: left;

    @media ${sizes.XP} {
        font-size: 18px;
        margin: 0 15px;
    }

    @media ${sizes.M} {
        font-size: 20px;
    }
`;

export const OptionsContainer = styled.div`
    display: grid;
    justify-items: start;
    font-family: PoppinsRegular;

    @media ${sizes.XP} {
        margin: 25px 15px;
    }

    @media ${sizes.M} {
        margin: 20px 0;
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;

    @media ${sizes.XP} {
        margin-bottom: 28px;
    }

    @media ${sizes.M} {
        margin-bottom: 24px;
    }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const CustomCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    background-size: cover;

    @media ${sizes.XP} {
        width: 23px;
        height: 23px;
        padding: 0 12px;
        background-image: url(${(props) => (props.checked ? MobileChecked : MobileNormal)});
    }

    @media ${sizes.M} {
        width: 18px;
        height: 18px;
        padding: 0;
        background-image: url(${(props) => (props.checked ? DesktopChecked : DesktopNormal)});
    }
`;

export const OptionLabel = styled.label<{ disabled?: boolean }>`
    text-align: left;

    ${(props) => props.disabled && `opacity: 50%;`};

    @media ${sizes.XP} {
        margin: 0 20px;
    }

    @media ${sizes.M} {
        margin: 0 14px;
    }
`;
