import styled from 'styled-components';

import { IUser } from '../../slices/userSlice';

import { colours } from '../../constants/colours';
import Bin from '../../assets/icons/bin.svg';
import { IIconComponent } from '../../styles/common';
import ActiveIcon from '../../assets/icons/status_active.svg';
import DropoutIcon from '../../assets/icons/status_dropout.svg';

interface IAction {
    color?: string;
    pointer?: boolean;
}

export const UsersContainer = styled.div`
    padding-top: 20px;
    max-width: 1335px;
    width: 90%;
    margin: 0 auto;
    font-family: PoppinsRegular;
    font-size: 14px;
`;

export const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    height: 61px;
    padding-right: 30px;
    border-bottom: solid 1px ${colours.grey};
`;

export const Header = styled.div`
    cursor: pointer;
    display: flex;
    height: 40px;
    padding-right: 30px;
    justify-content: flex-end;
    align-items: center;
    font-family: PoppinsMedium;
    flex-flow: row wrap;
    background-color: ${colours.lightGrey};
`;

export const Item = styled.div`
    flex: 0 0 15%;
    padding: 10px;
    box-sizing: border-box;
`;

export const AccordionIcon = styled.img`
    width: 12px;
    margin-right: 8px;
    transform: rotate(-90deg);
    cursor: pointer;

    &.open {
        transform: rotate(0deg);
    }
`;

export const UserStatusColumn = styled.div<{
    hideIcon?: boolean;
    droppedOut?: IUser['droppedOut'];
}>`
    width: 8%;
    flex: 0 0 8%;
    height: ${(props) => (!props.hideIcon ? `10px` : `auto`)};
    background: ${(props) =>
        !props.hideIcon
            ? `transparent url(${
                  !props.droppedOut ? ActiveIcon : DropoutIcon
              }) no-repeat 10px center`
            : ''};
`;

export const SortIcon = styled.img<{
    order: boolean;
}>`
    margin-left: 5px;
    transform: ${(props) => (props.order ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const UserId = styled.div`
    width: 8%;
    flex: 0 0 8%;
`;

export const FirstName = styled.div`
    width: 16%;
    flex: 0 0 16%;
`;

export const LastName = styled.div`
    width: 16%;
    flex: 0 0 16%;
`;

export const Email = styled.div`
    width: 33%;
    flex: 0 0 33%;
`;

export const Action = styled.div<IAction>`
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
    width: 7%;
    flex: 0 0 7%;
    color: ${(props) => (props.color ? props.color : colours.purple)};
`;

export const BinItem = styled.div<IIconComponent>`
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    cursor: ${(props) => (!props.hideIcon ? 'pointer' : 'default')};
    pointer-events: ${(props) => (!props.hideIcon ? 'default' : 'none')};
    background-image: url(${(props) => (!props.hideIcon ? Bin : '')});
`;
