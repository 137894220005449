import styled from 'styled-components';

import { colours } from '../../constants/colours';

export interface IButtonPage {
    active: boolean;
}

export const ListHeader = styled.div`
    display: flex;
    width: 90%;
    max-width: 1335px;
    align-items: center;
`;

export const TotalRecords = styled.div`
    font-family: 'PoppinsRegular';
    font-size: 16px;
    margin-left: 16px;
`;

export const SearchInput = styled.input`
    border-radius: 50px;
    width: 427px;
    height: 44px;
    padding: 12px 18px;

    &:focus {
        outline: none;
    }
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;

    label {
        cursor: pointer;
    }
`;

export const RadioButton = styled.input`
    margin-left: 20px;
    margin-right: 8px;
`;

export const ButtonPage = styled.span<IButtonPage>`
    cursor: ${(props) => (props.active ? `pointer` : `default`)};
    font-family: 'NunitoSemiBold';
    font-size: 18px;
    margin: 10px;
    color: ${(props) => (props.active ? colours.purple : colours.mediumGrey)};
`;
