import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Layouts
import HomeLayout from '../../layouts/home';

// Styles
import {
    Error,
    FieldContainer,
    FieldTitle,
    FormContainer,
    SearchResults,
    SearchButtons,
    Select,
    FieldHint,
    SuccessMessage,
    ErrorLink,
    EmailLink,
    SuccessBox,
    RequiredField,
} from './styles';
import { TopText } from '../consent/styles';
import { Textfield } from '../../components/TextfieldQuestion/styles';

// Utils
import { ROUTES } from '../../constants/routes';
import { useStateDebounced } from '../../hooks';
import canadaPost from '../../constants/canadaPost';
import { Button } from '../../components/Button';
import {
    createPasswordAndProfile,
    selectCreatePasswordAndProfile,
    selectCreatePasswordAndProfileError,
} from '../../slices/createPasswordAndProfile';
import {
    validateFirstName,
    validateLastName,
    validatePhone,
    validateFirstAddressLine,
    validatePostalCode,
    validateCity,
    validateProvince,
} from '../../utils/validation';
import LANGUAGES from '../../constants/languages';
import LINKS from '../../constants/links';

export enum Provinces {
    AB = 'AB',
    BC = 'BC',
    MB = 'MB',
    NB = 'NB',
    NL = 'NL',
    NT = 'NT',
    NS = 'NS',
    NU = 'NU',
    ON = 'ON',
    PE = 'PE',
    QC = 'QC',
    SK = 'SK',
    YT = 'YT',
}

type OptionType = {
    value: string;
    label: string;
    next: string;
};

enum Next {
    Find = 'Find',
    Retrieve = 'Retrieve',
}

export interface IParams {
    token: string;
}

const Profile: React.FunctionComponent = () => {
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useStateDebounced(search, 400);
    const [locations, setLocations] = useState<OptionType[]>([]);

    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const history = useHistory();

    const getEmail = () => {
        if (language === LANGUAGES.EN) return `mailto:${LINKS.INFOEMAIL_EN}`;
        return `mailto:${LINKS.INFOEMAIL_FR}`;
    };

    const dispatch = useDispatch();
    const params = useParams<IParams>();

    const createdPasswordAndProfile = useSelector(selectCreatePasswordAndProfile);
    const createdPasswordAndProfileError = useSelector(selectCreatePasswordAndProfileError);

    const handleAnswer = (field: string, answer: string) => {
        switch (field) {
            case 'firstName':
                setFirstName(answer);
                break;
            case 'lastName':
                setLastName(answer);
                break;
            case 'password':
                setPassword(answer);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(answer);
                break;
            case 'postalCode':
                setPostalCode(answer);
                break;
            case 'address1':
                setAddress1(answer);
                break;
            case 'address2':
                setAddress2(answer);
                break;
            case 'city':
                setCity(answer);
                break;
            case 'province':
                setProvince(answer);
                break;
            case 'phone':
                setPhone(answer);
                break;
            default:
        }
    };

    const sendCreatePasswordAndProfile = () => {
        setPasswordError(password !== passwordConfirmation);
        if (password !== passwordConfirmation) return;

        dispatch(
            createPasswordAndProfile({
                token: params.token,
                user: {
                    password,
                    profile: {
                        firstName,
                        lastName,
                        postalCode,
                        firstAddressLine: address1,
                        secondAddressLine: address2,
                        city,
                        province,
                        phone,
                    },
                },
            }),
        );
    };

    const getMask = (field: string) => {
        if (field === 'postalCode') {
            return 'a9a9a9';
        } else if (field === 'phone') {
            return '(999) 999-9999';
        }
        return '';
    };

    const displayField = (field: string, value: string, required: boolean, onblur?: () => void) => {
        return (
            <FieldContainer>
                <FieldTitle>
                    {t(`profile.${field}`)}
                    {required && '*'}
                </FieldTitle>
                <Textfield
                    type={field.includes('password') ? 'password' : 'text'}
                    name={field}
                    value={value}
                    mask={getMask(field)}
                    onChange={(event) => handleAnswer(field, event.target.value)}
                    required
                />
            </FieldContainer>
        );
    };

    const checkForm = () => {
        return (
            validateFirstName(firstName) &&
            validateLastName(lastName) &&
            password === passwordConfirmation &&
            password.length > 0 &&
            validatePostalCode(postalCode) &&
            validateFirstAddressLine(address1) &&
            validateCity(city) &&
            validateProvince(province) &&
            validatePhone(phone)
        );
    };

    async function fetchData(debouncedSearch: string, lastId: string) {
        let responseItems = [];
        const response = await axios.get(canadaPost.search(debouncedSearch, lastId));
        if (response?.data?.Items) {
            // Since errors return 200, check if there is an error
            if (!response.data.Items[0].Error) {
                responseItems = response.data.Items.map((item: any) => ({
                    value: item.Id,
                    label: `${item.Text}, ${item.Description}`,
                    next: item.Next,
                }));
            }
        }
        setLocations(responseItems);
    }

    useEffect(() => {
        fetchData(debouncedSearch, '');
    }, [debouncedSearch]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleLocationSelect = async (location: OptionType) => {
        const id = location.value;
        const next = location.next;
        if (next === Next.Retrieve) {
            const response = await axios.get(canadaPost.getDetails(id));
            if (response?.data?.Items) {
                // Since errors return 200, check if there is an error
                if (!response.data.Items[0].Error) {
                    const selected = response.data.Items[0];
                    setAddress1(`${selected.Line1}`);
                    setPostalCode(selected.PostalCode.replace(/\s/g, ''));
                    setCity(selected.City);
                    setProvince(selected.Province);
                    setSearch('');
                }
            }
        } else if (next === Next.Find) {
            fetchData(debouncedSearch, id);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t(`profile.profilePage`)}</title>
            </Helmet>
            <HomeLayout>
                {!createdPasswordAndProfile && (
                    <>
                        <TopText>{t(`profile.title`)}</TopText>
                        <RequiredField>{t('requiredFields')}</RequiredField>
                        <FormContainer>
                            {displayField('password', password, true)}
                            {displayField('passwordConfirmation', passwordConfirmation, true)}
                            {passwordError && <Error>{t('login.matchPasswords')}</Error>}
                            {displayField('firstName', firstName, true)}
                            {displayField('lastName', lastName, true)}
                            {displayField('phone', phone, true)}
                            <FieldContainer>
                                <FieldTitle>{t('profile.searchAddress')}</FieldTitle>
                                <Textfield
                                    type={'text'}
                                    name={'search'}
                                    mask=""
                                    value={search}
                                    onChange={handleSearch}
                                    required
                                />
                                <FieldHint>{t('profile.autofill')}</FieldHint>
                            </FieldContainer>
                            <SearchResults>
                                {locations.map((location) => (
                                    <SearchButtons
                                        onClick={() => handleLocationSelect(location)}
                                        key={location.label}
                                    >
                                        {location.label}
                                    </SearchButtons>
                                ))}
                            </SearchResults>
                            {displayField('address1', address1, true)}
                            {displayField('address2', address2, false)}
                            {displayField('postalCode', postalCode, true)}
                            {displayField('city', city, true)}
                            <FieldContainer>
                                <FieldTitle>
                                    {t('profile.province')}
                                    {'*'}
                                </FieldTitle>
                                <Select
                                    value={province}
                                    onChange={(event) =>
                                        handleAnswer('province', event.target.value)
                                    }
                                >
                                    <option key={''} value={''} />
                                    {Object.values(Provinces).map((provinceOption) => (
                                        <option key={provinceOption} value={provinceOption}>
                                            {t(`survey.${provinceOption}`)}
                                        </option>
                                    ))}
                                </Select>
                            </FieldContainer>
                            {createdPasswordAndProfileError && (
                                <Error>
                                    <Trans i18nKey="profile.updateError">
                                        Something went wrong. Please check the form and try again.
                                        If you have already signed up,
                                        <ErrorLink to={ROUTES.LOGIN}>please click here</ErrorLink>.
                                    </Trans>
                                    <div></div>
                                    <Trans i18nKey="profile.updateError2">
                                        For all other inquiries, please contact us at
                                        <EmailLink href={getEmail()}>
                                            info@encorestudy.ca
                                        </EmailLink>{' '}
                                        or by phone/SMS at 1-866-362-6730.
                                    </Trans>
                                </Error>
                            )}
                            <Button
                                label={t('admin.send')}
                                onClick={sendCreatePasswordAndProfile}
                                justifySelf="center"
                                active={checkForm()}
                            />
                        </FormContainer>
                    </>
                )}
                {createdPasswordAndProfile && (
                    <SuccessBox>
                        <SuccessMessage to={ROUTES.LOGIN}>
                            {t(`profile.updateSuccess`)}
                        </SuccessMessage>
                        <Button
                            label={t('login.login')}
                            onClick={() => history.push(ROUTES.LOGIN)}
                            justifySelf="center"
                            active={true}
                        />
                    </SuccessBox>
                )}
            </HomeLayout>
        </>
    );
};

export default Profile;
