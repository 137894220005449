import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const Textfield = styled(InputMask)`
    font-family: PoppinsRegular;
    width: 332px;
    height: 48px;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;
`;

export const TextArea = styled.textarea`
    font-family: PoppinsRegular;
    resize: none;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;

    @media ${sizes.XP} {
        width: 332px;
        height: 200px;
    }

    @media ${sizes.G} {
        width: 800px;
        height: 200px;
    }
`;

export const Label = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    max-width: 332px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const MultiTextfieldContainer = styled.div`
    display: grid;
    place-items: center;
    grid-template-rows: auto;
    height: 60%;
`;
