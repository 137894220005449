import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const LogoutButton = styled.div`
    color: ${colours.purple};
    font-size: 16px;
    cursor: pointer;
    background-color: ${colours.lightGrey};
    border-radius: 10px;
    border: none;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
    }

    &:hover {
        background-color: ${colours.mediumGrey};
    }
`;
